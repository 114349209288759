import React, { Fragment, useEffect, useState } from "react";
import { cn, fDate, hideId, numeralMoney } from "../utils/cn";
import { Link } from "react-router-dom";
import { CD, minExchange, packagesConst, tabs } from "../constants/mainConst";
import StarIcon from "../components/icons/StarIcon";
import { Tab } from "@headlessui/react";

import ExchangeForm from "../components/ExchangeForm";
import GetGiftCode from "../components/GetGiftCode";
import PackageTable from "../components/PackageTable";
import DiamonIcon from "../components/icons/DiamonIcon";
import HistoryExhangeTable from "../components/HistoryExhangeTable";
import { faker } from "@faker-js/faker";
import CommentFb from "../components/Comment/CommentFb";
import { linkMessage } from "../constants/link";

const dataMarquee = [...Array(20).keys()].map((i) => ({
  uuid: faker.number.int(1000000000, 9999999999),
  value: packagesConst.filter((item) => item.value > 0)[
    Math.floor(Math.random() * 3)
  ].value,
  time: new Date(Date.now() - Math.floor(Math.random() * 10000000000)),
}));

const countTime = (cd) => {
  const countdownTime = new Date(fDate(new Date())).getTime() + cd * 1000;
  const now = new Date().getTime();
  const distance = countdownTime - now;
  return distance;
};

const Home = () => {
  const [countdown, setCountdown] = useState(countTime(CD));
  const [packages] = useState(packagesConst);

  const [days, hours, minutes, seconds] = [
    Math.floor(countdown / (1000 * 60 * 60 * 24)),
    Math.floor((countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60)),
    Math.floor((countdown % (1000 * 60)) / 1000),
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => prev - 1000);
    }, 1000);

    if (countdown <= 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [countdown]);

  return (
    <div className="mt-4 flex flex-col gap-y-7">
      <img src="/images/A3AKQLe.png" alt="A3AKQLe" className="w-full h-full" />
      <div className="flex space-x-2  items-center">
        <img src="/images/icon.png" alt="icon" className="w-8 h-8" />
        <span className="text-xl font-bold leading-8 tracking-wider">
          NẠP THẺ FREEFIRE UY TÍN
        </span>
      </div>
      <div className="p-4 rounded-sm bg-[#d9edf7] text-[#31708f] border border-[#bce8f1] text-sm ">
        <p className="before:content-['\e086']">
          Ưu Đãi: Nhận thêm giftcode tự chọn khi nạp thẻ có giá trị từ{" "}
          <strong>{numeralMoney(minExchange)}đ</strong> trở lên
        </p>
        <p className="flex items-baseline gap-x-1">
          <span className=" space-x-1 inline-block">
            <span className="before:content-['\e086'] ">
              Ưu Đãi: Nhận thêm skin súng tự chọn khi nạp thẻ có giá trị từ
            </span>
            <strong>{numeralMoney(100000)}đ</strong>
            <span>trở lên (áp dụng cho lần nạp đầu tiên)</span>
          </span>
          <img
            src="/images/new.gif"
            alt="new"
            className="w-auto h-3 align-middle"
          />
        </p>
      </div>
      <div className="w-full lg:w-9/12 lg:mx-auto -mt-2 mb-8">
        <div className="w-full rounded-xl px-5 py-3  bg-gradient-to-r from-[#ff0000] to-[#ff8400] flex items-center justify-between ">
          <div className="flex items-center">
            <img
              src="/images/icon_thunder_sale_2021.png"
              alt="icon_thunder_sale_2021"
              className="w-auto h-auto"
            />
            <img
              src="/images/icon_thunder_sale_2021.png"
              alt="icon_thunder_sale_2021"
              className="w-auto h-auto"
            />
            <Link to={"/"} className="italic text-white text-lg ">
              Sự kiện <strong className="text-yellow-200">kết thúc sau</strong>!
            </Link>
          </div>
          <div>
            <ul className="flex">
              <li className="text-lg">
                <strong className="bg-white px-2.5 py-0.5 rounded-md ">
                  {days > 0 ? days : 0}
                </strong>
                <span className="text-white pr-1 pl-1.5">:</span>
              </li>
              <li className="text-lg">
                <strong className="bg-white px-2.5 py-0.5 rounded-md ">
                  {hours > 0 ? hours : 0}
                </strong>
                <span className="text-white pr-1 pl-1.5">:</span>
              </li>
              <li className="text-lg">
                <strong className="bg-white px-2.5 py-0.5 rounded-md ">
                  {minutes > 0 ? minutes : 0}
                </strong>
                <span className="text-white pr-1 pl-1.5">:</span>
              </li>
              <li className="text-lg">
                <strong className="bg-white px-2.5 py-0.5 rounded-md ">
                  {seconds > 0 ? seconds : 0}
                </strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <marquee scrollamount="10">
        <div className="flex items-center space-x-1.5 h-5">
          {dataMarquee.map((item, index) => (
            <Fragment key={index}>
              <li className="space-x-1.5 flex items-center text-[15px]">
                <div>
                  <b
                    title="text"
                    rel="dofollow"
                    style={{ color: "blue" }}
                    className="text-[15px]"
                  >
                    [Chúc mừng]
                  </b>
                </div>
                <div className="py-0.5 px-1  bg-[#0dbd13] text-white font-medium text-xs rounded-md flex items-center ">
                  <StarIcon className={"text-white "} />
                  <span className={``}>ID {hideId(item.uuid)}</span>
                </div>
              </li>
              <li className="space-x-2 flex items-center text-[15px]">
                Đã nạp thành công
              </li>
              <li
                className={cn("space-x-2 flex items-center text-[15px]", {
                  "text-purple-500": item.value === 2300,
                  "text-green-500": item.value === 4600,
                  "text-[#0066ff]": item.value === 9400,
                  "text-red-500": item.value === 9100,
                })}
              >
                <b className="font-bold">{numeralMoney(item.value)}</b>
              </li>
              <span className="text-[15px]">Kim Cương</span>
              <b className="font-semibold text-[15px]">
                {fDate(item.time, "yyyy | hh:mm:ss")}
              </b>
            </Fragment>
          ))}
        </div>
      </marquee>
      <div className="w-full rounded-sm bg-[#dff0d8] text-[#3c763d] border border-[#d6e9c6] p-4 mb-5 text-sm">
        <b className="font-bold">
          Nạp Kim Cương Free Fire Nhận Quà Cực Vip,
          <b className="text-red-500 font-bold">
            {" "}
            Cam Kết Nạp Trực Tiếp Qua UID, Không Yêu Cầu Mật Khẩu, Kim Cương
            Sạch 100% Từ Nhà Phát Hành, Tự Động Xử Lý Trong 30 Giây!
          </b>
        </b>
      </div>
      <div className="w-full min-h-[430px]">
        <Tab.Group>
          <Tab.List className={"space-x-3 lg:w-2/3 w-full"}>
            {tabs.map((item, index) => (
              <Tab
                key={index}
                className={({ selected }) =>
                  cn(
                    "py-2.5 text-sm font-medium px-4 rounded-md focus:outline-none border-2 border-[#962022] uppercase",
                    {
                      "text-white shadow bg-[#006eff]": selected,
                      "text-[#385898]": !selected,
                    }
                  )
                }
              >
                {item}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className={"mt-5"}>
            <Tab.Panel>
              <ExchangeForm></ExchangeForm>
            </Tab.Panel>
            <Tab.Panel>
              <GetGiftCode></GetGiftCode>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      <div className="w-full -mt-4">
        <div className="w-full mb-2">
          <PackageTable data={packages}></PackageTable>
        </div>
        <div className="w-full ">
          <HistoryExhangeTable></HistoryExhangeTable>
        </div>
      </div>
      <div className="w-full">
        <CommentFb></CommentFb>
      </div>
      <div className="w-full -mt-4">
        <h1 className="text-xl font-bold">LỢI ÍCH KHI NẠP THẺ TẠI ĐÂY</h1>
        <svg
          className="h2-svg mt-5"
          width="164"
          height="8"
          viewBox="0 0 164 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M128.866 0L120.779 8H0V0H128.866Z" fill="#FFBA00"></path>
          <path
            d="M140.577 0L132.491 8H127.92L136.006 0H140.577Z"
            fill="#FFBA00"
          ></path>
          <path
            d="M152.289 0L144.202 8H139.621L147.718 0H152.289Z"
            fill="#FFBA00"
          ></path>
          <path
            d="M164 0L155.904 8H151.333L159.429 0H164Z"
            fill="#FFBA00"
          ></path>
        </svg>
        <table className="table border w-full mt-4">
          <tbody className="bg-white">
            <tr className="bg-gray-50">
              <td className="text-base p-2 border">✅ Nạp kim cương uy tín</td>
              <td className="text-base p-2 border">
                <strong>⭐</strong>
                Kim cương sạch 100% được nhiều Youtube sử dụng
              </td>
            </tr>
            <tr className="">
              <td className="text-base p-2 border">✅ Nạp kim cương giá rẻ</td>
              <td className="text-base p-2 border">
                <strong>⭐</strong>
                Giá nạp rẻ, nhận thêm các phần quà hấp dẫn trực tiếp qua id game
              </td>
            </tr>
            <tr className="bg-gray-50">
              <td className="text-base p-2 border">✅ Nạp kim cương nhanh</td>
              <td className="text-base p-2 border">
                <strong>⭐</strong>
                Chỉ 30s sau khi thanh toán là kim cương đã có trong tài khoản
              </td>
            </tr>
            <tr className="">
              <td className="text-base p-2 border">✅ Nạp kim cương an toàn</td>
              <td className="text-base p-2 border">
                <strong>⭐</strong>
                Nạp kc qua ID game, không cần phải đăng nhập tài khoản hay mật
                khẩu
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="w-full  text-base">
        <h1 className="font-semibold">Hướng dẫn nạp thẻ Free Fire</h1>
        <svg
          className="h2-svg mt-5"
          width="164"
          height="8"
          viewBox="0 0 164 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M128.866 0L120.779 8H0V0H128.866Z" fill="#FFBA00"></path>
          <path
            d="M140.577 0L132.491 8H127.92L136.006 0H140.577Z"
            fill="#FFBA00"
          ></path>
          <path
            d="M152.289 0L144.202 8H139.621L147.718 0H152.289Z"
            fill="#FFBA00"
          ></path>
          <path
            d="M164 0L155.904 8H151.333L159.429 0H164Z"
            fill="#FFBA00"
          ></path>
        </svg>

        <div className="mt-5 space-y-4 w-full">
          <div className="w-full">
            <p>
              <strong>Bước 1:</strong> Ấn vào phần răng cưa ở góc bên phải phía
              trên màn hình chính
            </p>
            <div className="w-full flex justify-center">
              <img
                src="/images/huong-dan-nap-the-img1.jpg"
                alt="huong-dan-nap-the-img1"
                className="w-2/3 h-auto"
              />
            </div>
          </div>
          <div className="w-full">
            <p>
              <strong>Bước 2:</strong> Kéo xuống phần UID
            </p>
            <div className="w-full flex justify-center">
              <img
                src="/images/huong-dan-nap-the-img2.jpg"
                alt="huong-dan-nap-the-img2"
                className="w-2/3 h-auto"
              />
            </div>
          </div>
          <div className="w-full">
            <p>
              <strong>Bước 3:</strong> Click vào biểu tượng copy phía cuối của
              UID để copy
            </p>
            <div className="w-full flex justify-center">
              <img
                src="/images/huong-dan-nap-the-img3.jpg"
                alt="huong-dan-nap-the-img3"
                className="w-2/3 h-auto"
              />
            </div>
          </div>
          <div className="w-full">
            <p>
              <strong>Bước 4:</strong> Cuối cùng, sao chép ID Game vừa sao chép
              -{">"} Chọn gói nạp -{">"}{" "}
              <strong>Điền Thông Tin Thẻ,ID Game</strong> . Sau khi nạp thành
              công hệ thống sẽ xử lý thẻ trong 30s và xử lý vật phẩm về tài
              khoản của bạn.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full text-base">
        <h1 className="font-semibold">Giới thiệu Garena Free Fire</h1>
        <svg
          className="h2-svg mt-1"
          width="164"
          height="8"
          viewBox="0 0 164 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M128.866 0L120.779 8H0V0H128.866Z" fill="#FFBA00"></path>
          <path
            d="M140.577 0L132.491 8H127.92L136.006 0H140.577Z"
            fill="#FFBA00"
          ></path>
          <path
            d="M152.289 0L144.202 8H139.621L147.718 0H152.289Z"
            fill="#FFBA00"
          ></path>
          <path
            d="M164 0L155.904 8H151.333L159.429 0H164Z"
            fill="#FFBA00"
          ></path>
        </svg>
        <div className="mt-5 w-full leading-5">
          <p>
            Free Fire là thể loại game có lối chơi nhanh và rất thú vị, đồ họa
            mượt mà và tối ưu trên mọi thiết bị. Không chỉ vậy, tựa game sinh
            tồn hấp dẫn này còn được chơi hoàn toàn miễn phí và hỗ trợ tiếng
            Việt cũng như bạn có thể nạp kim cương Free Fire cực dễ dàng. Nhờ
            những yếu tố này, Free Fire đã nhanh chóng lọt vào top 5 của các
            bảng xếp hạng game và đứng top hot về nạp thẻ free fire ngay sau khi
            phát hành.
          </p>
          <p>
            Mở đầu trò chơi, người chơi sẽ vào một chiếc máy bay quân sự và đi
            qua một bản đồ. Tại thời điểm này, người chơi có thể nhảy bất cứ nơi
            nào họ muốn, cho phép họ lựa chọn vị trí chiến lược để tấn công hoặc
            tránh xa kẻ thù.
          </p>
          <p>
            Sau khi hạ cánh, người chơi phải tìm kiếm trên đảo để tìm v.khí và
            các vật dụng tiện ích, bộ dụng cụ y tế, lựu đạn và các vật phẩm
            khác.
          </p>
          <p>
            Sau khi tìm thấy các vật phẩm cơ bản như v.khí, áo giáp, mũ, v.v.,
            bạn có thể tham gia vào cuộc phục kích của đối thủ. Tuy nhiên, hãy
            nhớ rằng sẽ có một vòng tròn trên bản đồ, vòng tròn này sẽ tự động
            thu nhỏ khi hết giờ, và những người bên ngoài vòng tròn sẽ bị mất
            HP, khi bạn mua thêm vật phẩm cần phải nạp thẻ free fire. Sử dụng nó
            để giành ưu thế bằng cách phục kích kẻ thù đang cố gắng chạy từ
            ngoài vào trong.
          </p>
          <p>
            Mục tiêu cuối cùng của người chơi là sống sót trên một hòn đảo với
            tối đa 100 người chơi trực tuyến, điều này đòi hỏi bạn phải loại bỏ
            tất cả những đối thủ mà người chơi gặp phải trên đường đi và đảm bảo
            bạn là người sống sót duy nhất. Nếu muốn mua những trang bị khủng
            đừng quên hãy nạp kim cương nhé.
          </p>
        </div>
      </div>

      <a
        href={linkMessage}
        className="fixed bottom-5 right-5 z-50 "
        target="_blank"
        rel="noreferrer"
      >
        <img src={"/images/messager.svg"} alt="messager" className="" />
      </a>
    </div>
  );
};

export default Home;
