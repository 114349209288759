import React, { memo } from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import ConfirmModal from "../Modal/ConfirmModal";
import Bottom from "./components/Bottom";

const Layout = () => {
  return (
    <div className="lg:overflow-x-hidden font-sans">
      <Header />
      <div className="bg-white w-full h-full  pt-4 pb-2.5 mb-16 flex justify-center">
        <div className="xl:w-7/12 lg:w-8/12 2xl:w-6/12 w-full lg:px-10 px-3.5">
          <Outlet></Outlet>
        </div>
      </div>
      <ConfirmModal></ConfirmModal>
      <Bottom></Bottom>
    </div>
  );
};

export default memo(Layout);
