import React from "react";
import PropTypes from "prop-types";
import { cn } from "../../utils/cn";

const CheckShieldIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={cn(className)}
    >
      <path
        fill="currentColor"
        d="M21 11c0 5.55-3.84 10.74-9 12c-5.16-1.26-9-6.45-9-12V5l9-4l9 4zm-9 10c3.75-1 7-5.46 7-9.78V6.3l-7-3.12L5 6.3v4.92C5 15.54 8.25 20 12 21m-2-4l-4-4l1.41-1.41L10 14.17l6.59-6.59L18 9"
      ></path>
    </svg>
  );
};

CheckShieldIcon.propTypes = {
  className: PropTypes.string,
};

export default CheckShieldIcon;
