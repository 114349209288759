import React, { Fragment, useEffect, useState } from "react";
import CheckShieldIcon from "./icons/CheckShieldIcon";
import SpinerIcon from "./icons/SpinerIcon";
import { listHeader, options, packagesConst } from "../constants/mainConst";
import ClockIcon from "./icons/ClockIcon";
import {
  cn,
  fDate,
  generateRandomTableItems,
  hideId,
  numeralMoney,
  sortArray,
} from "../utils/cn";

const HistoryExhangeTable = () => {
  const [packages] = useState(packagesConst.filter((item) => item.value !== 0));
  const [data, setData] = useState(
    sortArray(generateRandomTableItems(options, packages))
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setData(sortArray(generateRandomTableItems(options, packages)));
    }, 1 * 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="space-y-1">
      <p className="text-xs">
        Lịch sử nạp thành công gần đây (Tự động cập nhật)
      </p>
      <p className="text-xs font-semibold flex items-center space-x-1">
        <CheckShieldIcon className={"text-[#1c1e21] w-3 h-3"}></CheckShieldIcon>
        Thống kê theo thời gian thực
        <SpinerIcon
          className={"text-red-500 w-3 h-3 animate-spin-slow"}
        ></SpinerIcon>
      </p>
      <svg
        className="h2-svg"
        width="164"
        height="8"
        viewBox="0 0 164 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M128.866 0L120.779 8H0V0H128.866Z" fill="#FFBA00"></path>
        <path
          d="M140.577 0L132.491 8H127.92L136.006 0H140.577Z"
          fill="#FFBA00"
        ></path>
        <path
          d="M152.289 0L144.202 8H139.621L147.718 0H152.289Z"
          fill="#FFBA00"
        ></path>
        <path d="M164 0L155.904 8H151.333L159.429 0H164Z" fill="#FFBA00"></path>
      </svg>
      <div className="pt-2">
        <table className="table border w-full">
          <thead className="border">
            <tr className=" border-t border-[#dee2e6]">
              {listHeader.map((item, index) => (
                <th
                  key={index}
                  className="p-2 border text-sm text-left font-medium uppercase "
                >
                  {index === 0 && (
                    <div className="flex items-center space-x-1">
                      <ClockIcon className={"text-black w-4 h-4"}></ClockIcon>
                      {item}
                    </div>
                  )}
                  {index !== 0 && item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white">
            {data.map((item, index) => (
              <tr className="border text-xs even:bg-gray-50" key={index}>
                <td className="p-2 border">
                  {fDate(new Date(item.time), "HH:mm:ss")}
                </td>
                <td className="p-2  border">{hideId(item.id)}</td>
                <td className="p-2  border">
                  {Reward(item?.price?.value || 0)}
                </td>
                <td className="p-2 border">
                  <span
                    className={cn(
                      "text-xs px-2 py-0.5 pb-1  text-white rounded-xl",
                      {
                        "bg-green-600": item.status,
                        "bg-red-600": !item.status,
                      }
                    )}
                  >
                    {item.status ? "Thành công" : "Thất bại"}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HistoryExhangeTable;

const Reward = (item) => {
  const [color] = {
    4200: ["text-purple-500"],
    9000: ["text-red-500"],
    25000: ["text-orange-500"],
    70000: ["text-blue-500"],
    150000: ["text-green-500"],
  }[item];
  return (
    <div className="flex items-center space-x-1">
      <span className={cn("font-bold text-sm ", color)}>
        +{numeralMoney(item)}
      </span>
      <img src="/images/diamond.png" alt="diamond" className="w-5 h-5" />
    </div>
  );
};
