import { faker } from "@faker-js/faker";
import clsx from "clsx";
import numeral from "numeral";
import { twMerge } from "tailwind-merge";
import { format } from "date-fns";

export function cn(...className) {
  return twMerge(clsx(className));
}

export function fDate(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy";

  return date ? format(new Date(date), fm) : "";
}

export const numeralMoney = (money) => {
  return numeral(money).format("0,0[.]00");
};

export const hideId = (id) => {
  return id.toString().slice(0, 6) + "****";
};
// new Date(year,month,day,hours,minutes,seconds)
export const genRandomItemtable = (options, optionsPrice) => {
  const date = new Date();

  return {
    id: faker.number.int(1000000000, 9999999999),
    serial: faker.number.int(1000000000, 9999999999),
    card: faker.helpers.arrayElement([...options]),
    status: true,
    // status: !(s % 5 === 0),
    time: date.getTime() - randomInt(0, 2 * 60 * 60 * 1000),
    price: faker.helpers.arrayElement(
      optionsPrice.filter((item) => item.value !== 0)
    ),
  };
};

export const generateRandomTableItems = (options, optionsPrice) => {
  const tableItems = [];
  for (let i = 0; i < 9; i++) {
    const item = genRandomItemtable(options, optionsPrice);
    tableItems.push(item);
  }
  return tableItems;
};

export const randomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const sortArray = (array, type = "desc") => {
  if (type === "asc") return array.sort((a, b) => a.time - b.time);
  return array.sort((a, b) => b.time - a.time);
};
