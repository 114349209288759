import { default as base } from "axios";

const headers = {
  "Content-Type": "application/json",
};

const axios = base.create({
  baseURL: "https://api.congnapgame.com",
  headers,
});

export default axios;
