import React from "react";
import PropTypes from "prop-types";
import { cn } from "../../utils/cn";

const ClockIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      className={cn(className)}
    >
      <path
        fill="currentColor"
        d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28m0 192a92 92 0 1 1 92-92a92.1 92.1 0 0 1-92 92m60-92a4 4 0 0 1-4 4h-56a4 4 0 0 1-4-4V72a4 4 0 0 1 8 0v52h52a4 4 0 0 1 4 4"
      ></path>
    </svg>
  );
};

ClockIcon.propTypes = {
  className: PropTypes.string,
};

export default ClockIcon;
