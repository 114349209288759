import { create } from "zustand";

export const useConfirmModal = create((set) => ({
  isOpen: false,
  isResolving: false,
  data: {
    title: "",
    description: "",
    icon: "info",
  },
  setIsOpen: (isOpen) => set({ isOpen }),
  show: (data) => set({ isOpen: true, data }),
  update: (data) => set((state) => ({ ...state, data })),
  async resolve(result) {
    if (result) {
      set({ isResolving: true });
      try {
        await this.data.onConfirm?.();
        set({ isOpen: false });
        // Wait for the modal to close
        setTimeout(() => {
          set({ isResolving: false });
        }, 500);
      } catch (err) {
        set({ isResolving: false });
      }
    } else {
      try {
        await this.data.onCancel?.();
        set({ isOpen: false });
      } catch (err) {}
    }
  },
  reset() {
    set({
      data: { title: "", description: "", icon: "info" },
    });
  },
}));
