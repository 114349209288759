import React, { memo } from "react";
import PropTypes from "prop-types";
import DiamonIcon from "./icons/DiamonIcon";
import { listHeaderPackage } from "../constants/mainConst";
import { numeralMoney } from "../utils/cn";

const PackageTable = ({ data }) => {
  return (
    <div>
      <h3 className="text-center text-[#007bff] text-sm font-bold">
        BẢNG GIÁ NẠP THẺ
      </h3>
      <table className="table border w-full">
        <thead className="border">
          <tr className="bg-[#e9ecef] text-[#495057] border-t border-[#dee2e6]">
            {listHeaderPackage.map((item, index) => (
              <th key={index} className="p-2 border text-sm">
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-sm">
          {data
            .filter((item) => item.value !== 0)
            .map((item, index) => (
              <tr className="border " key={index}>
                <td className="p-2 border font-semibold text-[11px]">
                  {numeralMoney(item.vnd)} VND
                </td>
                <td className="p-2 flex items-center  font-semibold text-[11px]">
                  {numeralMoney(item.value)}
                  <img
                    src="/images/diamond.png"
                    alt="diamond"
                    className="w-5 h-auto mx-0.5"
                  />
                  {item.hasCode && (
                    <span className="text-red-500 text-xs ml-2 font-semibold">
                      + Gói Code
                    </span>
                  )}
                  {item.hasGift && (
                    <span className="text-blue-500 text-xs ml-2 font-semibold">
                      + Gói Quà
                    </span>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

PackageTable.propTypes = {
  data: PropTypes.array,
};

export default memo(PackageTable);
