import { createBrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import { HOME } from "./path";
import Layout from "../components/layout";
import Home from "../pages/Home";

export const routes = createBrowserRouter([
  {
    path: HOME,
    element: (
      <Suspense fallback={<></>}>
        <Layout></Layout>
      </Suspense>
    ),
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<></>}>
            <Home />
          </Suspense>
        ),
      },
    ],
  },
]);
