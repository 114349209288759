import React, { useEffect, useState } from "react";
import { useConfirmModal } from "../hooks/useConfirmModal";
import { Cards, options, packagesConst } from "../constants/mainConst";
import { useForm } from "react-hook-form";
import { numeralMoney } from "../utils/cn";
import Input from "./Form/Input";
import CircleInfoIcon from "./icons/CircleInfoIcon";
import ListBox from "./Form/ListBox";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useExchangeMutation } from "../core/exchange/exchange.query";

const schema = yup.object().shape({
  uuid: yup.string().required("ID tài khoản không được để trống"),
  serial: yup.string(),
  card: yup.string(),
  type: yup.string(),
  package: yup.string(),
});

const ExchangeForm = () => {
  const confirmModalState = useConfirmModal();
  const [packages, setPackages] = useState(packagesConst);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid, isSubmitted, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const {
    mutate,
    error,
    isPending,
    data: dataExchange,
  } = useExchangeMutation();

  useEffect(() => {
    const tmpsArray = packages.map((item, index) => {
      if (item.value === 0) {
        return item;
      }
      return {
        ...item,
        label: (
          <span className="flex items-center " key={index}>
            {numeralMoney(item.vnd)}đ - {numeralMoney(item.value)} Kim Cương
          </span>
        ),
      };
    });

    setPackages(tmpsArray);
    setValue("type", options[0].value);
  }, []);

  useEffect(() => {
    if (dataExchange) {
      confirmModalState.show({
        title: "Nạp Thẻ Thành Công",
        description: (
          <p className="px-5 py-1 bg-green-100 text-green-600 rounded-md">
            Bạn vui lòng đợi hệ thống kiểm tra thẻ nếu thẻ hết giá trị sử dụng
            thì lệnh sẻ bị huỷ
          </p>
        ),
        icon: "success",
      });
    }
  }, [dataExchange]);

  useEffect(() => {
    if (error) {
      confirmModalState.show({
        title: "Có Lỗi Xảy Ra",
        description: (
          <p className="px-5 py-1 bg-red-100 text-red-600 rounded-md">
            {error.message}
          </p>
        ),
        icon: "error",
      });
    }
  }, [error]);

  const onSubmit = (data) => {
    console.log("onSubmit ~ data:", data.card.length, data.serial.length);
    if (!checkCard(data)) {
      showDialogError();
      return;
    }

    mutate({
      account_id: data.uuid,
      card_type: data.type,
      packageOption: data.package,
      serial: data.serial,
      card_code: data.card,
    });
  };

  const checkCard = ({ type, serial, card }) => {
    if (type === Cards.VIETTEL) {
      if (serial.length === 11 && card.length === 13) {
        return true;
      }

      if (serial.length === 14 && card.length === 15) {
        return true;
      }
    }

    if (type === Cards.MOBIFONE && serial.length === 15 && card.length === 12) {
      return true;
    }

    if (
      type === Cards.VINAPHONE &&
      serial.length === 14 &&
      card.length === 14
    ) {
      return true;
    }

    if (
      type === Cards.GARENA &&
      (serial.length === 8 || serial.length === 9) &&
      card.length === 16
    ) {
      return true;
    }

    if (type === Cards.VIETNAMOBILE && card.length === 12) {
      if (serial.length === 11 || serial.length === 16) {
        return true;
      }
    }

    return false;
  };

  const showDialogError = () => {
    confirmModalState.show({
      title: "Có Lỗi Xảy Ra",
      description: (
        <p className="px-5 py-1 bg-red-100 text-red-600 rounded-md">
          Thẻ không hợp lệ
        </p>
      ),
      icon: "error",
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-5 w-full">
      <div className="w-full">
        <Input
          type="text"
          name="uuid"
          display="column"
          placeholder="Nhập ID game của bạn"
          {...register("uuid")}
          label="ID Game"
        />
        <p className="text-[15px] text-red-600">
          Nhập đúng UID , tránh nhập sai tài khoản người khác
        </p>
        {errors &&
          isSubmitted &&
          !isValid &&
          isDirty &&
          errors.uuid?.message && (
            <div className="w-full mt-1">
              <span className="text-left flex items-center text-xs text-red-500 bg-red-50 pl-4 py-1 rounded-lg font-medium gap-x-2">
                <CircleInfoIcon
                  className={"h-3.5 w-3.5 fill-current text-red-500"}
                />
                {errors.uuid?.message}
              </span>
            </div>
          )}
      </div>

      <ListBox
        options={options}
        className={"w-full"}
        setValue={(value) => {
          setValue("type", value.value.toString().toUpperCase());
        }}
        display="column"
        label={"Loại thẻ"}
      />
      <ListBox
        options={packages}
        className={"w-full"}
        setValue={(value) => {
          setValue("package", value.vnd);
        }}
        display="column"
        defaultValue={packages[0]}
        label={"Mệnh giá thẻ"}
      />

      <div className="w-full">
        <Input
          type="text"
          name="card"
          display="column"
          placeholder="Nhập mã thẻ cào in trên thẻ"
          {...register("card")}
          label="Mã thẻ"
        />
        {errors &&
          isSubmitted &&
          !isValid &&
          isDirty &&
          errors.card?.message && (
            <div className="w-full mt-1">
              <span className="text-left flex items-center text-xs text-red-500 bg-red-50 pl-4 py-1 rounded-lg font-medium gap-x-2">
                <CircleInfoIcon
                  className={"h-3.5 w-3.5 fill-current text-red-500"}
                />
                {errors.card?.message}
              </span>
            </div>
          )}
      </div>
      <div className="w-full">
        <Input
          type="text"
          name="serial"
          display="column"
          placeholder="Nhập số seri in trên thẻ"
          {...register("serial")}
          label="Số seri"
        />
        {errors &&
          isSubmitted &&
          !isValid &&
          isDirty &&
          errors.serial?.message && (
            <div className="w-full mt-1">
              <span className="text-left flex items-center text-xs text-red-500 bg-red-50 pl-4 py-1 rounded-lg font-medium gap-x-2">
                <CircleInfoIcon
                  className={"h-3.5 w-3.5 fill-current text-red-500"}
                />
                {errors.serial?.message}
              </span>
            </div>
          )}
      </div>

      <button
        type="submit"
        className="w-full bg-red-600 text-white border border-[#dc3545] hover:opacity-90 mt-2 py-1.5 mb-5 uppercase text-xs rounded-md"
        disabled={isPending}
      >
        Nạp kim cương
      </button>
    </form>
  );
};

export default ExchangeForm;
