import axios from "../../lib/ClientApi";

export const postExchange = async ({
  account_id,
  card_type,
  packageOption,
  serial,
  card_code,
}) =>
  axios
    .post("/exchange-card", {
      account_id,
      card_type,
      package: parseInt(packageOption),
      serial,
      card_code,
    })
    .then((res) => res.data);
