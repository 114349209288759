import React from "react";

const Header = () => {
  return (
    <div className="w-full py-4 pb-10 border-b">
      <div className="lg:w-6/12 w-full lg:mx-auto lg:px-10 px-3.5 flex justify-center lg:justify-end items-center">
        <div className="mr-7">
          <img
            src="/images/png-clipart-garena-rov-mobile-moba-league-of-legends-computer-icons-random-icons-text-logo-thumbnail.png"
            alt="png-clipart-garena-rov-mobile-moba-league-of-legends-computer-icons-random-icons-text-logo-thumbnail"
            className="w-8 h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
