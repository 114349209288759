import React from "react";

import "./style/_yz1uahXI_7d496.css";
import "./style/gp1vM8uX1o4d560.css";
import "./style/rDJlsbuFhhc2a66.css";
import "./style/d6SccDA_fE8e9b7.css";
import "./style/SHSK2BjfwgT9996.css";
import "./style/BfTjxijlArm1f5b.css";
import "./style/QSCrUkg0svy63ba.css";
import "./style/jP1ajhsYKVnaf51.css";
import "./style/extend.css";

const CommentFb = () => {
  return (
    <div className="_li">
      <div className="pluginSkinLight pluginFontHelvetica">
        <div>
          <div className="_56q9">
            <div className="_2pi8">
              <div
                className="_491z clearfix"
                style={{ width: "100%", height: 50 }}
              >
                <div className="_ohe lfloat">
                  <span>
                    <span className=" _50f7">25 bình luận</span>
                  </span>
                </div>
                <div className="_ohf rfloat">
                  <div>
                    <span className="_pup">Sắp xếp theo</span>
                    <select className="_3-8_ _33ki" initialvalue="time">
                      <option value="reverse_time">Mới nhất</option>
                      <option value="time">Cũ nhất</option>
                      <option value="toplevel" />
                    </select>
                  </div>
                </div>
              </div>
              <div className="_4uyl _1zz7 _2392 clearfix" direction="left">
                <div className="_ohe lfloat">
                  <div className="img _8o _8s UFIImageBlockImage" />
                </div>
                <div className="">
                  <div className="UFIImageBlockContent _42ef">
                    <div>
                      <div className="UFIInputContainer">
                        <textarea
                          id="inputBox"
                          aria-label="Thêm bình luận"
                          className="_1cb _1u9t"
                          placeholder="Viết bình luận..."
                          defaultValue={""}
                        />
                        <div className="UFICommentAttachmentButtons clearfix" />
                      </div>
                      <div className="_4uym">
                        <div className="_5tr6 clearfix _2ph- clearfix">
                          <div className="_ohe lfloat">
                            <span />
                          </div>
                          <div className="_ohf rfloat">
                            <span>
                              <button
                                id="submitButton"
                                className="rfloat _3-99 _4jy0 _4jy3 _4jy1 _51sy selected _42ft"
                                type="submit"
                                value={1}
                                disabled=""
                              >
                                Đăng
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="_4k-6 hehe"></div>

              <div className="_4k-6">
                <div className="_3-8y _5nz1 clearfix" direction="left">
                  <div className="_ohe lfloat">
                    <a
                      href="https://www.facebook.com/freefirevn?mibextid=LQQJ4d&gidzl=3Tg_9B2SyWy8mBahiORm2Gx2kY-eXQ4E6PAuAlU4eLr8akee-T6dNHUQx2okZFG85CYzBZA8SdeJj9ls0m"
                      src=""
                      target="_blank"
                      className="img _8o _8s UFIImageBlockImage"
                    >
                      <img className="_1ci img" src="/images/cmt1.jpg" alt="" />
                    </a>
                  </div>

                  <div className="">
                    <div
                      className="UFIImageBlockContent _42ef clearfix"
                      direction="right"
                    >
                      <div className="_ohf rfloat">
                        <div />
                      </div>
                      <div className="">
                        <div>
                          <span>
                            <a
                              target="_blank"
                              className=" UFICommentActorName  flex items-center space-x-1"
                              dir="ltr"
                              href="https://www.facebook.com/freefirevn?mibextid=LQQJ4d&gidzl=3Tg_9B2SyWy8mBahiORm2Gx2kY-eXQ4E6PAuAlU4eLr8akee-T6dNHUQx2okZFG85CYzBZA8SdeJj9ls0m"
                            >
                              <span>Garena Free Fire</span>
                              <img
                                width="13px"
                                src="https://freepngimg.com/thumb/facebook/65667-blue-verified-url-media-checkmark-facebook-social.png"
                              />
                            </a>
                          </span>
                          <div className="_3-8m">
                            <div className="_30o4">
                              <span>
                                <span className="_5mdd">
                                  <span>
                                    Hiện tại garena đang hợp tác tổ chức sự kiện
                                    nạp kim cương tại CONGNAPGAME.COM các bạn
                                    tham gia có thể truy cập tham gia ngay nhé
                                  </span>
                                </span>
                              </span>
                              <span />
                            </div>
                          </div>
                          <div className="_2vq9 fsm fwn fcg">
                            <a href="#">thích</a>
                            <span aria-hidden="true"> · </span>
                            <a href="#">Phản hồi</a>
                            <span aria-hidden="true"> · </span>
                            <span aria-hidden="true" className="ml-2">
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_63b8bf cus like"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_b7d5e5 cus wow"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_f49dc4 cus tym"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 livetimestamp "
                                  alt=""
                                  data-visualcompletion="css-img"
                                />{" "}
                                &nbsp;10k
                              </span>
                              ·{" "}
                            </span>
                            <abbr
                              aria-label="khoảng một phút trước"
                              minimize="true"
                              className="UFISutroCommentTimestamp livetimestamp"
                              data-utime={1686231863}
                              data-minimize="true"
                              data-shorten="true"
                            >
                              1 ngày trước
                            </abbr>
                          </div>
                        </div>
                        {/* comment */}
                        <div className="_44ri _2pis">
                          <div className="_3-8y clearfix" direction="left">
                            <div className="_ohe">
                              <a
                                href="https://www.facebook.com/BeLaKhanhLy"
                                src=""
                                target="_blank"
                                className="img _8o _8s UFIImageBlockImage"
                              >
                                <img
                                  className="_1ci img"
                                  src="/images/cmt2.jpg"
                                  alt=""
                                />
                              </a>
                            </div>
                            <div className="">
                              <div
                                className="UFIImageBlockContent _42ef clearfix"
                                direction="right"
                              >
                                <div className="_ohf">
                                  <div />
                                </div>
                                <div className="">
                                  <div>
                                    <span>
                                      <a
                                        target="_blank"
                                        className=" UFICommentActorName"
                                        dir="ltr"
                                        href="https://www.facebook.com/BeLaKhanhLy"
                                      >
                                        Bé Là Khánh Ly
                                      </a>
                                    </span>
                                    <div className="_3-8m">
                                      <div className="_30o4">
                                        <span>
                                          <span className="_5mdd">
                                            <span>
                                              Nạp CONGNAPGAME.COM nhiều kim
                                              cương thật
                                            </span>
                                          </span>
                                        </span>
                                        <span />
                                      </div>
                                    </div>
                                    <div className="_2vq9 fsm fwn fcg">
                                      <a href="#">Thích</a>
                                      <span aria-hidden="true"> · </span>
                                      <a href="#">Phản hồi</a>
                                      <span aria-hidden="true"> · </span>
                                      <span>
                                        <abbr
                                          aria-label="3 giờ trước"
                                          minimize="true"
                                          className="UFISutroCommentTimestamp livetimestamp"
                                          data-utime={1707671852}
                                          data-minimize="true"
                                          data-shorten="true"
                                        >
                                          4 giờ
                                        </abbr>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="_3-8y clearfix" direction="left">
                            <div className="_ohe">
                              <a
                                href="https://www.facebook.com/people/Kh%C3%A1nh-Duy/pfbid0yqJweaTRto5HinuTManHacahHS3G4KAbm1M7ufYPazi1McX3sWFjtJFQ3PdrxX21l/"
                                src=""
                                target="_blank"
                                className="img _8o _8s UFIImageBlockImage"
                              >
                                <img
                                  className="_1ci img"
                                  src="https://freefire.momovoucher.com/avatar.php?uid=100084611972973"
                                  alt=""
                                />
                              </a>
                            </div>
                            <div className="">
                              <div
                                className="UFIImageBlockContent _42ef clearfix"
                                direction="right"
                              >
                                <div className="_ohf">
                                  <div />
                                </div>
                                <div className="">
                                  <div>
                                    <span>
                                      <a
                                        target="_blank"
                                        className=" UFICommentActorName"
                                        dir="ltr"
                                        href="https://www.facebook.com/people/Kh%C3%A1nh-Duy/pfbid0yqJweaTRto5HinuTManHacahHS3G4KAbm1M7ufYPazi1McX3sWFjtJFQ3PdrxX21l/"
                                      >
                                        Khánh Duy
                                      </a>
                                    </span>
                                    <div className="_3-8m">
                                      <div className="_30o4">
                                        <span>
                                          <span className="_5mdd">
                                            <span>
                                              Cái này đúng chính hãng này, nạp
                                              cái về nhanh vãi ấy
                                            </span>
                                          </span>
                                        </span>
                                        <span />
                                      </div>
                                    </div>
                                    <div className="_2vq9 fsm fwn fcg">
                                      <a href="#">Thích</a>
                                      <span aria-hidden="true"> · </span>
                                      <a href="#">Phản hồi</a>
                                      <span aria-hidden="true"> · </span>
                                      <span>
                                        <abbr
                                          aria-label="3 giờ trước"
                                          minimize="true"
                                          className="UFISutroCommentTimestamp livetimestamp"
                                          data-utime={1707671852}
                                          data-minimize="true"
                                          data-shorten="true"
                                        >
                                          2 giờ
                                        </abbr>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="_3-8y _5nz1 clearfix" direction="left">
                  <div className="_ohe lfloat">
                    <a
                      href="https://www.facebook.com/MaGamingFF?mibextid=LQQJ4d"
                      src=""
                      target="_blank"
                      className="img _8o _8s UFIImageBlockImage"
                    >
                      <img className="_1ci img" src="/images/cmt3.jpg" alt="" />
                    </a>
                  </div>

                  <div className="">
                    <div
                      className="UFIImageBlockContent _42ef clearfix"
                      direction="right"
                    >
                      <div className="_ohf rfloat">
                        <div />
                      </div>
                      <div className="">
                        <div>
                          <span>
                            <a
                              target="_blank"
                              className=" UFICommentActorName"
                              dir="ltr"
                              href="https://www.facebook.com/MaGamingFF?mibextid=LQQJ4d"
                            >
                              Ma Gaming
                            </a>
                          </span>
                          <div className="_3-8m">
                            <div className="_30o4">
                              <span>
                                <span className="_5mdd">
                                  <span>
                                    Hiện tại mình cũng đã tham gia sự kiện tại
                                    đây rồi nhé các bạn, anh em nhanh vào nạp
                                    ngay nào
                                  </span>
                                </span>
                              </span>
                              <span />
                            </div>
                          </div>
                          <div className="_2vq9 fsm fwn fcg">
                            <a href="#">thích</a>
                            <span aria-hidden="true"> · </span>
                            <a href="#">Phản hồi</a>
                            <span aria-hidden="true"> · </span>
                            <span aria-hidden="true" className="ml-2">
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_63b8bf cus like"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_f49dc4 cus tym"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_b7d5e5 cus wow"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 livetimestamp "
                                  alt=""
                                  data-visualcompletion="css-img"
                                />{" "}
                                &nbsp;8k
                              </span>
                              ·{" "}
                            </span>
                            <abbr
                              aria-label="khoảng một phút trước"
                              minimize="true"
                              className="UFISutroCommentTimestamp livetimestamp"
                              data-utime={1686231863}
                              data-minimize="true"
                              data-shorten="true"
                            >
                              1 ngày trước
                            </abbr>
                          </div>
                        </div>
                      </div>
                      {/* comment */}
                      <div className="_44ri _2pis">
                        <div className="_3-8y clearfix" direction="left">
                          <div className="_ohe">
                            <a
                              href="https://www.facebook.com/people/Gia-Kh%C3%A1nh/pfbid02rro2qSNwwhNKWpskMVz3H4PtR7YTqYxXyuPCMxwQkHKgDS2iDVXWw3gEWtDWZuxVl/"
                              src=""
                              target="_blank"
                              className="img _8o _8s UFIImageBlockImage"
                            >
                              <img
                                className="_1ci img"
                                src="images/cmt4.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="">
                            <div
                              className="UFIImageBlockContent _42ef clearfix"
                              direction="right"
                            >
                              <div className="_ohf">
                                <div />
                              </div>
                              <div className="">
                                <div>
                                  <span>
                                    <a
                                      target="_blank"
                                      className=" UFICommentActorName"
                                      dir="ltr"
                                      href="https://www.facebook.com/people/Gia-Kh%C3%A1nh/pfbid02rro2qSNwwhNKWpskMVz3H4PtR7YTqYxXyuPCMxwQkHKgDS2iDVXWw3gEWtDWZuxVl/"
                                    >
                                      Gia Khánh
                                    </a>
                                  </span>
                                  <div className="_3-8m">
                                    <div className="_30o4">
                                      <span>
                                        <span className="_5mdd">
                                          <span>Em cũng vừa tham gia luôn</span>
                                        </span>
                                      </span>
                                      <span />
                                    </div>
                                  </div>
                                  <div className="_2vq9 fsm fwn fcg">
                                    <a href="#">Thích</a>
                                    <span aria-hidden="true"> · </span>
                                    <a href="#">Phản hồi</a>
                                    <span aria-hidden="true"> · </span>
                                    <span>
                                      <abbr
                                        aria-label="3 giờ trước"
                                        minimize="true"
                                        className="UFISutroCommentTimestamp livetimestamp"
                                        data-utime={1707671852}
                                        data-minimize="true"
                                        data-shorten="true"
                                      >
                                        4 giờ
                                      </abbr>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="_3-8y _5nz1 clearfix" direction="left">
                  <div className="_ohe lfloat">
                    <a
                      href="https://www.facebook.com/BacGau.ikonix"
                      src=""
                      target="_blank"
                      className="img _8o _8s UFIImageBlockImage "
                    >
                      <img
                        className="_1ci img"
                        src="https://i.imgur.com/vutmiTK.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="">
                    <div
                      className="UFIImageBlockContent _42ef clearfix"
                      direction="right"
                    >
                      <div className="_ohf rfloat">
                        <div />
                      </div>
                      <div className="">
                        <div>
                          <span>
                            <a
                              target="_blank"
                              className=" UFICommentActorName flex items-center space-x-1"
                              dir="ltr"
                              href="https://www.facebook.com/BacGau.ikonix"
                            >
                              <span>Bác Gấu</span>{" "}
                              <img
                                width="13px"
                                src="https://freepngimg.com/thumb/facebook/65667-blue-verified-url-media-checkmark-facebook-social.png"
                              />
                            </a>
                          </span>
                          <div className="_3-8m">
                            <div className="_30o4">
                              <span>
                                <span className="_5mdd">
                                  <span>
                                    CONGNAPGAME.COM là trang hợp tác chính thức
                                    của Garena Thầy cũng đã tham gia rồi nhé các
                                    em
                                  </span>
                                </span>
                              </span>
                              <span />
                            </div>
                          </div>
                          <div className="_2vq9 fsm fwn fcg">
                            <a href="#">thích</a>
                            <span aria-hidden="true"> · </span>
                            <a href="#">Phản hồi</a>
                            <span aria-hidden="true" className="ml-2">
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_63b8bf cus like"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_b7d5e5 cus wow"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_f49dc4 cus tym"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 livetimestamp "
                                  alt=""
                                  data-visualcompletion="css-img"
                                />{" "}
                                &nbsp;4k
                              </span>
                              ·{" "}
                            </span>
                            <abbr
                              aria-label="khoảng một phút trước"
                              minimize="true"
                              className="UFISutroCommentTimestamp livetimestamp"
                              data-utime={1686231863}
                              data-minimize="true"
                              data-shorten="true"
                            >
                              2 ngày trước
                            </abbr>
                          </div>
                        </div>
                      </div>
                      <div className="_44ri _2pis">
                        <div className="_3-8y clearfix" direction="left">
                          <div className="_ohe">
                            <a
                              href="https://www.facebook.com/people/B%C3%B9i-%C4%90%E1%BB%A9c-H%E1%BA%ADu/pfbid02bRi4ocDCsACouFv2tqxxmH5rRT5fdgCeuahiRkEGRh9wkhKoTtx6R5wmJ418K4n8l/"
                              src=""
                              target="_blank"
                              className="img _8o _8s UFIImageBlockImage"
                            >
                              <img
                                className="_1ci img"
                                src="/images/cmt5.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="">
                            <div
                              className="UFIImageBlockContent _42ef clearfix"
                              direction="right"
                            >
                              <div className="_ohf">
                                <div />
                              </div>
                              <div className="">
                                <div>
                                  <span>
                                    <a
                                      target="_blank"
                                      className=" UFICommentActorName"
                                      dir="ltr"
                                      href="https://www.facebook.com/people/B%C3%B9i-%C4%90%E1%BB%A9c-H%E1%BA%ADu/pfbid02bRi4ocDCsACouFv2tqxxmH5rRT5fdgCeuahiRkEGRh9wkhKoTtx6R5wmJ418K4n8l/"
                                    >
                                      Bùi Đức Hậu
                                    </a>
                                  </span>
                                  <div className="_3-8m">
                                    <div className="_30o4">
                                      <span>
                                        <span className="_5mdd">
                                          <span>
                                            Đã quá ạ, em cũng vừa nạp mà kc về
                                            nhanh quá
                                          </span>
                                        </span>
                                      </span>
                                      <span />
                                    </div>
                                  </div>
                                  <div className="_2vq9 fsm fwn fcg">
                                    <a href="#">Thích</a>
                                    <span aria-hidden="true"> · </span>
                                    <a href="#">Phản hồi</a>
                                    <span aria-hidden="true"> · </span>
                                    <span>
                                      <abbr
                                        aria-label="3 giờ trước"
                                        minimize="true"
                                        className="UFISutroCommentTimestamp livetimestamp"
                                        data-utime={1707671852}
                                        data-minimize="true"
                                        data-shorten="true"
                                      >
                                        4 giờ
                                      </abbr>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* comment */}
                  </div>
                </div>

                <div className="_3-8y _5nz1 clearfix" direction="left">
                  <div className="_ohe lfloat">
                    <a
                      href="https://www.facebook.com/asmobilestreamer?mibextid=LQQJ4d"
                      src=""
                      target="_blank"
                      className="img _8o _8s UFIImageBlockImage"
                    >
                      <img className="_1ci img" src="/images/cmt6.jpg" alt="" />
                    </a>
                  </div>

                  <div className="">
                    <div
                      className="UFIImageBlockContent _42ef clearfix"
                      direction="right"
                    >
                      <div className="_ohf rfloat">
                        <div />
                      </div>
                      <div className="">
                        <div>
                          <span>
                            <a
                              target="_blank"
                              className=" UFICommentActorName flex items-center space-x-1"
                              dir="ltr"
                              href="https://www.facebook.com/asmobilestreamer?mibextid=LQQJ4d"
                            >
                              <span>Nguyễn Hữu Sang</span>
                              <img
                                width="13px"
                                src="https://freepngimg.com/thumb/facebook/65667-blue-verified-url-media-checkmark-facebook-social.png"
                              />
                            </a>
                          </span>
                          <div className="_3-8m">
                            <div className="_30o4">
                              <span>
                                <span className="_5mdd">
                                  <span>
                                    Hiện tại As mobile cũng đã tham gia nhé,
                                    chần chừ gì nữa mà không tham gia ngay nào
                                    các chiến binh
                                  </span>
                                </span>
                              </span>
                              <span />
                            </div>
                          </div>
                          <div className="_2vq9 fsm fwn fcg">
                            <a href="#">thích</a>
                            <span aria-hidden="true"> · </span>
                            <a href="#">Phản hồi</a>
                            <span aria-hidden="true"> · </span>
                            <span aria-hidden="true" className="ml-2">
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_63b8bf cus like"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_b7d5e5 cus wow"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 livetimestamp "
                                  alt=""
                                  data-visualcompletion="css-img"
                                />{" "}
                                &nbsp;10k
                              </span>
                              ·{" "}
                            </span>
                            <abbr
                              aria-label="khoảng một phút trước"
                              minimize="true"
                              className="UFISutroCommentTimestamp livetimestamp"
                              data-utime={1686231863}
                              data-minimize="true"
                              data-shorten="true"
                            >
                              2 ngày trước
                            </abbr>
                          </div>
                        </div>
                      </div>
                      <div className="_44ri _2pis">
                        <div className="_3-8y clearfix" direction="left">
                          <div className="_ohe">
                            <a
                              href="https://www.facebook.com/people/Baoo-Ngocc/pfbid0qBNzqt49GRFkmGB5m76qt32yacyKdthpnPGzUdKRWfMbfKUkhJyXpEJDKbBpwhU8l/"
                              src=""
                              target="_blank"
                              className="img _8o _8s UFIImageBlockImage"
                            >
                              <img
                                className="_1ci img"
                                src="/images/cmt7.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="">
                            <div
                              className="UFIImageBlockContent _42ef clearfix"
                              direction="right"
                            >
                              <div className="_ohf">
                                <div />
                              </div>
                              <div className="">
                                <div>
                                  <span>
                                    <a
                                      target="_blank"
                                      className=" UFICommentActorName"
                                      dir="ltr"
                                      href="https://www.facebook.com/people/Baoo-Ngocc/pfbid0qBNzqt49GRFkmGB5m76qt32yacyKdthpnPGzUdKRWfMbfKUkhJyXpEJDKbBpwhU8l/"
                                    >
                                      Baoo Ngocc
                                    </a>
                                  </span>
                                  <div className="_3-8m">
                                    <div className="_30o4">
                                      <span>
                                        <span className="_5mdd">
                                          <span>
                                            Để chiều đi học về em nạp ạ
                                          </span>
                                        </span>
                                      </span>
                                      <span />
                                    </div>
                                  </div>
                                  <div className="_2vq9 fsm fwn fcg">
                                    <a href="#">Thích</a>
                                    <span aria-hidden="true"> · </span>
                                    <a href="#">Phản hồi</a>
                                    <span aria-hidden="true"> · </span>
                                    <span>
                                      <abbr
                                        aria-label="3 giờ trước"
                                        minimize="true"
                                        className="UFISutroCommentTimestamp livetimestamp"
                                        data-utime={1707671852}
                                        data-minimize="true"
                                        data-shorten="true"
                                      >
                                        4 giờ
                                      </abbr>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* comment */}
                  </div>
                </div>

                <div className="_3-8y _5nz1 clearfix" direction="left">
                  <div className="_ohe lfloat">
                    <a
                      href="https://www.facebook.com/thuy.quynh.21.2?mibextid=LQQJ4d"
                      src=""
                      target="_blank"
                      className="img _8o _8s UFIImageBlockImage"
                    >
                      <img className="_1ci img" src="/images/cmt8.jpg" alt="" />
                    </a>
                  </div>

                  <div className="">
                    <div
                      className="UFIImageBlockContent _42ef clearfix"
                      direction="right"
                    >
                      <div className="_ohf rfloat">
                        <div />
                      </div>
                      <div className="">
                        <div>
                          <span>
                            <a
                              target="_blank"
                              className=" UFICommentActorName"
                              dir="ltr"
                              href="https://www.facebook.com/thuy.quynh.21.2?mibextid=LQQJ4d"
                            >
                              Trần Thúy Quỳnh
                            </a>
                          </span>
                          <div className="_3-8m">
                            <div className="_30o4">
                              <span>
                                <span className="_5mdd">
                                  <span>
                                    Chị cũng đã tham gia rồi nhé các em, sự kiện
                                    nạp kim cương đang hot nè
                                  </span>
                                </span>
                              </span>
                              <span />
                            </div>
                          </div>
                          <div className="_2vq9 fsm fwn fcg">
                            <a href="#">thích</a>
                            <span aria-hidden="true"> · </span>
                            <a href="#">Phản hồi</a>
                            <span aria-hidden="true"> · </span>
                            <span aria-hidden="true" className="ml-2">
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_63b8bf cus like"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_f49dc4 cus tym"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 livetimestamp "
                                  alt=""
                                  data-visualcompletion="css-img"
                                />{" "}
                                &nbsp;12k
                              </span>
                              ·{" "}
                            </span>
                            <abbr
                              aria-label="khoảng một phút trước"
                              minimize="true"
                              className="UFISutroCommentTimestamp livetimestamp"
                              data-utime={1686231863}
                              data-minimize="true"
                              data-shorten="true"
                            >
                              2 ngày trước
                            </abbr>
                          </div>
                        </div>
                      </div>
                      <div className="_44ri _2pis">
                        <div className="_3-8y clearfix" direction="left">
                          <div className="_ohe">
                            <a
                              href="https://www.facebook.com/thangnenhoko"
                              src=""
                              target="_blank"
                              className="img _8o _8s UFIImageBlockImage"
                            >
                              <img
                                className="_1ci img"
                                src="/images/cmt9.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="">
                            <div
                              className="UFIImageBlockContent _42ef clearfix"
                              direction="right"
                            >
                              <div className="_ohf">
                                <div />
                              </div>
                              <div className="">
                                <div>
                                  <span>
                                    <a
                                      target="_blank"
                                      className=" UFICommentActorName"
                                      dir="ltr"
                                      href="https://www.facebook.com/thangnenhoko"
                                    >
                                      Huỳnh Thắng
                                    </a>
                                  </span>
                                  <div className="_3-8m">
                                    <div className="_30o4">
                                      <span>
                                        <span className="_5mdd">
                                          <span>
                                            Có mấy anh chị comment là biết hàng
                                            real rồi
                                          </span>
                                        </span>
                                      </span>
                                      <span />
                                    </div>
                                  </div>
                                  <div className="_2vq9 fsm fwn fcg">
                                    <a href="#">Thích</a>
                                    <span aria-hidden="true"> · </span>
                                    <a href="#">Phản hồi</a>
                                    <span aria-hidden="true"> · </span>
                                    <span>
                                      <abbr
                                        aria-label="3 giờ trước"
                                        minimize="true"
                                        className="UFISutroCommentTimestamp livetimestamp"
                                        data-utime={1707671852}
                                        data-minimize="true"
                                        data-shorten="true"
                                      >
                                        4 giờ
                                      </abbr>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* comment */}
                  </div>
                </div>

                <div className="_3-8y _5nz1 clearfix" direction="left">
                  <div className="_ohe lfloat">
                    <a
                      href="https://www.facebook.com/profile.php?id=100044384740949&mibextid=LQQJ4d"
                      src=""
                      target="_blank"
                      className="img _8o _8s UFIImageBlockImage"
                    >
                      <img
                        className="_1ci img"
                        src="/images/cmt10.jpg"
                        alt=""
                      />
                    </a>
                  </div>

                  <div className="">
                    <div
                      className="UFIImageBlockContent _42ef clearfix"
                      direction="right"
                    >
                      <div className="_ohf rfloat">
                        <div />
                      </div>
                      <div className="">
                        <div>
                          <span>
                            <a
                              target="_blank"
                              className=" UFICommentActorName flex items-center space-x-1"
                              dir="ltr"
                              href="https://www.facebook.com/profile.php?id=100044384740949&mibextid=LQQJ4d"
                            >
                              <span>Gao Bạc Tv</span>
                              <img
                                width="13px"
                                src="https://freepngimg.com/thumb/facebook/65667-blue-verified-url-media-checkmark-facebook-social.png"
                              />
                            </a>
                          </span>
                          <div className="_3-8m">
                            <div className="_30o4">
                              <span>
                                <span className="_5mdd">
                                  <span>
                                    Gao bạc cũng đã tham gia sự kiện nạp kim
                                    cương rồi nhé, các em vào tham gia đón chào
                                    sự kiện nào
                                  </span>
                                </span>
                              </span>
                              <span />
                            </div>
                          </div>
                          <div className="_2vq9 fsm fwn fcg">
                            <a href="#">thích</a>
                            <span aria-hidden="true"> · </span>
                            <a href="#">Phản hồi</a>
                            <span aria-hidden="true"> · </span>
                            <span aria-hidden="true" className="ml-2">
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_63b8bf cus like"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_f49dc4 cus tym"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 livetimestamp "
                                  alt=""
                                  data-visualcompletion="css-img"
                                />{" "}
                                &nbsp;10k
                              </span>
                              ·{" "}
                            </span>
                            <abbr
                              aria-label="khoảng một phút trước"
                              minimize="true"
                              className="UFISutroCommentTimestamp livetimestamp"
                              data-utime={1686231863}
                              data-minimize="true"
                              data-shorten="true"
                            >
                              3 ngày trước
                            </abbr>
                          </div>
                        </div>
                      </div>
                      <div className="_44ri _2pis">
                        <div className="_3-8y clearfix" direction="left">
                          <div className="_ohe">
                            <a
                              href="https://www.facebook.com/tuanhuynh53"
                              src=""
                              target="_blank"
                              className="img _8o _8s UFIImageBlockImage"
                            >
                              <img
                                className="_1ci img"
                                src="/images/cmt11.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="">
                            <div
                              className="UFIImageBlockContent _42ef clearfix"
                              direction="right"
                            >
                              <div className="_ohf">
                                <div />
                              </div>
                              <div className="">
                                <div>
                                  <span>
                                    <a
                                      target="_blank"
                                      className=" UFICommentActorName"
                                      dir="ltr"
                                      href="https://www.facebook.com/tuanhuynh53"
                                    >
                                      Huỳnh Mino
                                    </a>
                                  </span>
                                  <div className="_3-8m">
                                    <div className="_30o4">
                                      <span>
                                        <span className="_5mdd">
                                          <span>
                                            Sự kiện này ngon nè, mình nạp rồi nè
                                          </span>
                                        </span>
                                      </span>
                                      <span />
                                    </div>
                                  </div>
                                  <div className="_2vq9 fsm fwn fcg">
                                    <a href="#">Thích</a>
                                    <span aria-hidden="true"> · </span>
                                    <a href="#">Phản hồi</a>
                                    <span aria-hidden="true"> · </span>
                                    <span>
                                      <abbr
                                        aria-label="3 giờ trước"
                                        minimize="true"
                                        className="UFISutroCommentTimestamp livetimestamp"
                                        data-utime={1707671852}
                                        data-minimize="true"
                                        data-shorten="true"
                                      >
                                        4 giờ
                                      </abbr>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="_3-8y clearfix" direction="left">
                          <div className="_ohe">
                            <a
                              href="https://www.facebook.com/people/Haiminh-Nong/pfbid02AXd7yjMK2vcn3pczxPCe7Mk3XT52DFr6kzKvADQSd2wLraHsRYtokhCZ26d4xUJWl/"
                              src=""
                              target="_blank"
                              className="img _8o _8s UFIImageBlockImage"
                            >
                              <img
                                className="_1ci img"
                                src="/images/cmt12.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="">
                            <div
                              className="UFIImageBlockContent _42ef clearfix"
                              direction="right"
                            >
                              <div className="_ohf">
                                <div />
                              </div>
                              <div className="">
                                <div>
                                  <span>
                                    <a
                                      target="_blank"
                                      className=" UFICommentActorName"
                                      dir="ltr"
                                      href="https://www.facebook.com/people/Haiminh-Nong/pfbid02AXd7yjMK2vcn3pczxPCe7Mk3XT52DFr6kzKvADQSd2wLraHsRYtokhCZ26d4xUJWl/"
                                    >
                                      Haiminh Nong
                                    </a>
                                  </span>
                                  <div className="_3-8m">
                                    <div className="_30o4">
                                      <span>
                                        <span className="_5mdd">
                                          <span>
                                            Có mấy anh chị comment là biết hàng
                                            real rồi
                                          </span>
                                        </span>
                                      </span>
                                      <span />
                                    </div>
                                  </div>
                                  <div className="_2vq9 fsm fwn fcg">
                                    <a href="#">Thích</a>
                                    <span aria-hidden="true"> · </span>
                                    <a href="#">Phản hồi</a>
                                    <span aria-hidden="true"> · </span>
                                    <span>
                                      <abbr
                                        aria-label="3 giờ trước"
                                        minimize="true"
                                        className="UFISutroCommentTimestamp livetimestamp"
                                        data-utime={1707671852}
                                        data-minimize="true"
                                        data-shorten="true"
                                      >
                                        4 giờ
                                      </abbr>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* comment */}
                  </div>
                </div>

                <div className="_3-8y _5nz1 clearfix" direction="left">
                  <div className="_ohe lfloat">
                    <a
                      href="https://www.facebook.com/congantv?mibextid=LQQJ4d"
                      src=""
                      target="_blank"
                      className="img _8o _8s UFIImageBlockImage"
                    >
                      <img
                        className="_1ci img"
                        src="/images/cmt13.jpg"
                        alt=""
                      />
                    </a>
                  </div>

                  <div className="">
                    <div
                      className="UFIImageBlockContent _42ef clearfix"
                      direction="right"
                    >
                      <div className="_ohf rfloat">
                        <div />
                      </div>
                      <div className="">
                        <div>
                          <span>
                            <a
                              target="_blank"
                              className=" UFICommentActorName"
                              dir="ltr"
                              href="https://www.facebook.com/congantv?mibextid=LQQJ4d"
                            >
                              Cô Ngân Tv
                            </a>
                          </span>
                          <div className="_3-8m">
                            <div className="_30o4">
                              <span>
                                <span className="_5mdd">
                                  <span>
                                    Cô cũng đú trend tham gia rồi nè, các em
                                    cùng tham gia với cô nhé
                                  </span>
                                </span>
                              </span>
                              <span />
                            </div>
                          </div>
                          <div className="_2vq9 fsm fwn fcg">
                            <a href="#">thích</a>
                            <span aria-hidden="true"> · </span>
                            <a href="#">Phản hồi</a>
                            <span aria-hidden="true"> · </span>
                            <span aria-hidden="true" className="ml-2">
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_b7d5e5 cus wow"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_63b8bf cus like"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_f49dc4 cus tym"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 livetimestamp "
                                  alt=""
                                  data-visualcompletion="css-img"
                                />{" "}
                                &nbsp;10k
                              </span>
                              ·{" "}
                            </span>
                            <abbr
                              aria-label="khoảng một phút trước"
                              minimize="true"
                              className="UFISutroCommentTimestamp livetimestamp"
                              data-utime={1686231863}
                              data-minimize="true"
                              data-shorten="true"
                            >
                              3 ngày trước
                            </abbr>
                          </div>
                        </div>
                      </div>
                      <div className="_44ri _2pis">
                        <div className="_3-8y clearfix" direction="left">
                          <div className="_ohe">
                            <a
                              href="https://www.facebook.com/phat.trannguyen.568"
                              src=""
                              target="_blank"
                              className="img _8o _8s UFIImageBlockImage"
                            >
                              <img
                                className="_1ci img"
                                src="/images/cmt14.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="">
                            <div
                              className="UFIImageBlockContent _42ef clearfix"
                              direction="right"
                            >
                              <div className="_ohf">
                                <div />
                              </div>
                              <div className="">
                                <div>
                                  <span>
                                    <a
                                      target="_blank"
                                      className=" UFICommentActorName"
                                      dir="ltr"
                                      href="https://www.facebook.com/phat.trannguyen.568"
                                    >
                                      Trầnn Nguyênn Phátt
                                    </a>
                                  </span>
                                  <div className="_3-8m">
                                    <div className="_30o4">
                                      <span>
                                        <span className="_5mdd">
                                          <span>
                                            Cái này đúng chính hãng này, nạp cái
                                            về nhanh vãi ấy
                                          </span>
                                        </span>
                                      </span>
                                      <span />
                                    </div>
                                  </div>
                                  <div className="_2vq9 fsm fwn fcg">
                                    <a href="#">Thích</a>
                                    <span aria-hidden="true"> · </span>
                                    <a href="#">Phản hồi</a>
                                    <span aria-hidden="true"> · </span>
                                    <span>
                                      <abbr
                                        aria-label="3 giờ trước"
                                        minimize="true"
                                        className="UFISutroCommentTimestamp livetimestamp"
                                        data-utime={1707671852}
                                        data-minimize="true"
                                        data-shorten="true"
                                      >
                                        4 giờ
                                      </abbr>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* comment */}
                  </div>
                </div>

                <div className="_3-8y _5nz1 clearfix" direction="left">
                  <div className="_ohe lfloat">
                    <a
                      href="https://vi-vn.facebook.com/phtnhan"
                      src=""
                      target="_blank"
                      className="img _8o _8s UFIImageBlockImage"
                    >
                      <img
                        className="_1ci img"
                        src="https://freefire.momovoucher.com/avatar.php?uid=100084611972973"
                        alt=""
                      />
                    </a>
                  </div>

                  <div className="">
                    <div
                      className="UFIImageBlockContent _42ef clearfix"
                      direction="right"
                    >
                      <div className="_ohf rfloat">
                        <div />
                      </div>
                      <div className="">
                        <div>
                          <span>
                            <a
                              target="_blank"
                              className=" UFICommentActorName"
                              dir="ltr"
                              href="https://vi-vn.facebook.com/phtnhan"
                            >
                              Phan Thanh Nhân
                            </a>
                          </span>
                          <div className="_3-8m">
                            <div className="_30o4">
                              <span>
                                <span className="_5mdd">
                                  <span>
                                    em thấy nhiều web giả mạo lắm ạ toàn lừa
                                    thôi, web này uy tín
                                  </span>
                                </span>
                              </span>
                              <span />
                            </div>
                          </div>
                          <div className="_2vq9 fsm fwn fcg">
                            <a href="#">thích</a>
                            <span aria-hidden="true"> · </span>
                            <a href="#">Phản hồi</a>
                            <span aria-hidden="true"> · </span>
                            <abbr
                              aria-label="khoảng một phút trước"
                              minimize="true"
                              className="UFISutroCommentTimestamp livetimestamp"
                              data-utime={1686231863}
                              data-minimize="true"
                              data-shorten="true"
                            >
                              4 ngày trước
                            </abbr>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="_3-8y _5nz1 clearfix" direction="left">
                  <div className="_ohe lfloat">
                    <a
                      href="https://www.facebook.com/BacGau.ikonix"
                      src=""
                      target="_blank"
                      className="img _8o _8s UFIImageBlockImage "
                    >
                      <img
                        className="_1ci img"
                        src="https://i.imgur.com/vutmiTK.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="">
                    <div
                      className="UFIImageBlockContent _42ef clearfix"
                      direction="right"
                    >
                      <div className="_ohf rfloat">
                        <div />
                      </div>
                      <div className="">
                        <div>
                          <span>
                            <a
                              target="_blank"
                              className=" UFICommentActorName flex items-center space-x-1"
                              dir="ltr"
                              href="https://www.facebook.com/BacGau.ikonix"
                            >
                              <span>Bác Gấu</span>{" "}
                              <img
                                width="13px"
                                src="https://freepngimg.com/thumb/facebook/65667-blue-verified-url-media-checkmark-facebook-social.png"
                              />
                            </a>
                          </span>
                          <div className="_3-8m">
                            <div className="_30o4">
                              <span>
                                <span className="_5mdd">
                                  <span>
                                    Web này garena hợp tác chính thức nên kim
                                    cương sạch bảo đảm an toàn nha các em
                                  </span>
                                </span>
                              </span>
                              <span />
                            </div>
                          </div>
                          <div className="_2vq9 fsm fwn fcg">
                            <a href="#">thích</a>
                            <span aria-hidden="true"> · </span>
                            <a href="#">Phản hồi</a>
                            <span aria-hidden="true" className="ml-2">
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_63b8bf cus like"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_b7d5e5 cus wow"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 img sp_0d2oqxMYE7L_1_5x sx_f49dc4 cus tym"
                                  alt=""
                                  data-visualcompletion="css-img"
                                />
                              </span>
                              <span>
                                <i
                                  className="_3-8_ _4iy4 livetimestamp "
                                  alt=""
                                  data-visualcompletion="css-img"
                                />{" "}
                                &nbsp;4k
                              </span>
                              ·{" "}
                            </span>
                            <abbr
                              aria-label="khoảng một phút trước"
                              minimize="true"
                              className="UFISutroCommentTimestamp livetimestamp"
                              data-utime={1686231863}
                              data-minimize="true"
                              data-shorten="true"
                            >
                              4 ngày trước
                            </abbr>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="_3-8y _5nz1 clearfix" direction="left">
                  <div className="_ohe lfloat">
                    <a
                      href="https://www.facebook.com/bethaocutehihi"
                      src=""
                      target="_blank"
                      className="img _8o _8s UFIImageBlockImage"
                    >
                      <img
                        className="_1ci img"
                        src="/images/cmt15.jpg"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="">
                    <div
                      className="UFIImageBlockContent _42ef clearfix"
                      direction="right"
                    >
                      <div className="_ohf rfloat">
                        <div />
                      </div>
                      <div className="">
                        <div>
                          <span>
                            <a
                              target="_blank"
                              className=" UFICommentActorName"
                              dir="ltr"
                              href="https://www.facebook.com/bethaocutehihi"
                            >
                              Nguyen Phuong Thao
                            </a>
                          </span>
                          <div className="_3-8m">
                            <div className="_30o4">
                              <span>
                                <span className="_5mdd">
                                  <span>ad cho em xin kc id 284350243</span>
                                </span>
                              </span>
                              <span />
                            </div>
                          </div>
                          <div className="_2vq9 fsm fwn fcg">
                            <a href="#">thích</a>
                            <span aria-hidden="true"> · </span>
                            <a href="#">Phản hồi</a>
                            <span aria-hidden="true"> · </span>
                            <a href="#">Xóa</a>
                            <span aria-hidden="true"> · </span>
                            <span>
                              <i
                                className="_3-8_ _4iy4 img sp_Ip2XZyfUSLx_1_5x sx_11a928"
                                alt=""
                                data-visualcompletion="css-img"
                              />
                            </span>
                            <span aria-hidden="true"> · </span>
                            <span>
                              <a
                                className="uiLinkSubtle"
                                href="?nang-tien-ca-the-little-mermaid-1?fb_comment_id=2401081760072083"
                                data-ft='{"tn":"N"}'
                                target="_blank"
                              >
                                <abbr
                                  aria-label="khoảng một phút trước"
                                  minimize="true"
                                  className="UFISutroCommentTimestamp livetimestamp"
                                  data-utime={1686231863}
                                  data-minimize="true"
                                  data-shorten="true"
                                >
                                  5 ngày trước
                                </abbr>
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="_44ri _2pis">
                        <div className="_3-8y clearfix" direction="left">
                          <div className="_ohe">
                            <a
                              href="https://www.facebook.com/people/Hong-Ngocc/pfbid0WNGAgG8wmWbgdmbGxpja9XmFFAXtk7JDUPgwhxYcbmqBNCXtw6idtapJeLWuzFool/"
                              src=""
                              target="_blank"
                              className="img _8o _8s UFIImageBlockImage"
                            >
                              <img
                                className="_1ci img"
                                src="/images/cmt16.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="">
                            <div
                              className="UFIImageBlockContent _42ef clearfix"
                              direction="right"
                            >
                              <div className="_ohf">
                                <div />
                              </div>
                              <div className="">
                                <div>
                                  <span>
                                    <a
                                      target="_blank"
                                      className=" UFICommentActorName"
                                      dir="ltr"
                                      href="https://www.facebook.com/people/Hong-Ngocc/pfbid0WNGAgG8wmWbgdmbGxpja9XmFFAXtk7JDUPgwhxYcbmqBNCXtw6idtapJeLWuzFool/"
                                    >
                                      Hong Ngocc
                                    </a>
                                  </span>
                                  <div className="_3-8m">
                                    <div className="_30o4">
                                      <span>
                                        <span className="_5mdd">
                                          <span>
                                            Co cai nit!! sự kiện này x5 kc{" "}
                                            <br /> nạp 50k bây giờ bằng 300k
                                            ngày thường á tr tự nạp đi
                                          </span>
                                        </span>
                                      </span>
                                      <span />
                                    </div>
                                  </div>
                                  <div className="_2vq9 fsm fwn fcg">
                                    <a href="#">Thích</a>
                                    <span aria-hidden="true"> · </span>
                                    <a href="#">Phản hồi</a>
                                    <span aria-hidden="true"> · </span>
                                    <span>
                                      <abbr
                                        aria-label="3 giờ trước"
                                        minimize="true"
                                        className="UFISutroCommentTimestamp livetimestamp"
                                        data-utime={1707671852}
                                        data-minimize="true"
                                        data-shorten="true"
                                      >
                                        2 giờ
                                      </abbr>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="_3-8y _5nz1 clearfix" direction="left">
                  <div className="_ohe lfloat">
                    <a
                      href="https://www.facebook.com/people/V%C4%83n-Nguy%E1%BB%85n/pfbid0hRm9qVp1Ap4tPaESo8mFtN6viaa6o6GF7ikXN7YhBe1yMdfF1CC21AzzwtGv8SCHl/"
                      src=""
                      target="_blank"
                      className="img _8o _8s UFIImageBlockImage"
                    >
                      <img
                        className="_1ci img"
                        src="/images/cmt17.jpg"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="">
                    <div
                      className="UFIImageBlockContent _42ef clearfix"
                      direction="right"
                    >
                      <div className="_ohf rfloat">
                        <div />
                      </div>
                      <div className="">
                        <div>
                          <span>
                            <a
                              target="_blank"
                              className=" UFICommentActorName"
                              dir="ltr"
                              href="https://www.facebook.com/people/V%C4%83n-Nguy%E1%BB%85n/pfbid0hRm9qVp1Ap4tPaESo8mFtN6viaa6o6GF7ikXN7YhBe1yMdfF1CC21AzzwtGv8SCHl/"
                            >
                              Văn Nguyễn
                            </a>
                          </span>
                          <div className="_3-8m">
                            <div className="_30o4">
                              <span>
                                <span className="_5mdd">
                                  <span>
                                    nạp 100k nhận thêm quà nữa :&gt; quá đã
                                  </span>
                                </span>
                              </span>
                              <span />
                            </div>
                          </div>
                          <div className="_2vq9 fsm fwn fcg">
                            <a href="#">thích</a>
                            <span aria-hidden="true"> · </span>
                            <a href="#">Phản hồi</a>
                            <span aria-hidden="true"> · </span>
                            <a href="#">Xóa</a>
                            <span aria-hidden="true"> · </span>
                            <span>
                              <i
                                className="_3-8_ _4iy4 img sp_Ip2XZyfUSLx_1_5x sx_11a928"
                                alt=""
                                data-visualcompletion="css-img"
                              />
                            </span>
                            <span aria-hidden="true"> · </span>
                            <span>
                              <a
                                className="uiLinkSubtle"
                                href="https://phimmoiyy.net/phim-le/nang-tien-ca-the-little-mermaid-1?fb_comment_id=2401081760072083"
                                data-ft='{"tn":"N"}'
                                target="_blank"
                              >
                                <abbr
                                  aria-label="khoảng một phút trước"
                                  minimize="true"
                                  className="UFISutroCommentTimestamp livetimestamp"
                                  data-utime={1686231863}
                                  data-minimize="true"
                                  data-shorten="true"
                                >
                                  5 ngày trước
                                </abbr>
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="_3-8y _5nz1 clearfix" direction="left">
                  <div className="_ohe lfloat">
                    <a
                      href="https://www.facebook.com/people/Kh%C3%A1nh-Phan/pfbid0byviCcktAaPj7PxeVBUW6mj3H3WahLFztjQufckHfcE8AJknq9Nj7HtTkGRgTWgTl/"
                      src=""
                      target="_blank"
                      className="img _8o _8s UFIImageBlockImage"
                    >
                      <img
                        className="_1ci img"
                        src="/images/cmt18.jpg"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="">
                    <div
                      className="UFIImageBlockContent _42ef clearfix"
                      direction="right"
                    >
                      <div className="_ohf rfloat">
                        <div />
                      </div>
                      <div className="">
                        <div>
                          <span>
                            <a
                              target="_blank"
                              className=" UFICommentActorName"
                              dir="ltr"
                              href="https://www.facebook.com/people/Kh%C3%A1nh-Phan/pfbid0byviCcktAaPj7PxeVBUW6mj3H3WahLFztjQufckHfcE8AJknq9Nj7HtTkGRgTWgTl/"
                            >
                              Khánh Phan
                            </a>
                          </span>
                          <div className="_3-8m">
                            <div className="_30o4">
                              <span>
                                <span className="_5mdd">
                                  <span>Có ai nạp được chưa</span>
                                </span>
                              </span>
                              <span />
                            </div>
                          </div>
                          <div className="_2vq9 fsm fwn fcg">
                            <a href="#">thích</a>
                            <span aria-hidden="true"> · </span>
                            <a href="#">Phản hồi</a>
                            <span aria-hidden="true"> · </span>
                            <a href="#">Xóa</a>
                            <span aria-hidden="true"> · </span>
                            <span>
                              <i
                                className="_3-8_ _4iy4 img sp_Ip2XZyfUSLx_1_5x sx_11a928"
                                alt=""
                                data-visualcompletion="css-img"
                              />
                            </span>
                            <span aria-hidden="true"> · </span>
                            <span>
                              <a
                                className="uiLinkSubtle"
                                href="https://phimmoiyy.net/phim-le/nang-tien-ca-the-little-mermaid-1?fb_comment_id=2401081760072083"
                                data-ft='{"tn":"N"}'
                                target="_blank"
                              >
                                <abbr
                                  aria-label="khoảng một phút trước"
                                  minimize="true"
                                  className="UFISutroCommentTimestamp livetimestamp"
                                  data-utime={1686231863}
                                  data-minimize="true"
                                  data-shorten="true"
                                >
                                  5 ngày trước
                                </abbr>
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="_3-8y _5nz1 clearfix" direction="left">
                  <div className="_ohe">
                    <a
                      href="https://www.facebook.com/people/Nguy%E1%BB%85n-Tr%C3%A2m/pfbid0F5UaJbgyQaAAWsiWhCe8V4qbeAtFNXLE5PLMQMQCjQQA1tNZVNy3muAjMQEnxmWRl/"
                      src=""
                      target="_blank"
                      className="img _8o _8s UFIImageBlockImage"
                    >
                      <img
                        className="_1ci img"
                        src="/images/cmt19.jpg"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="">
                    <div
                      className="UFIImageBlockContent _42ef clearfix"
                      direction="right"
                    >
                      <div className="_ohf">
                        <div />
                      </div>
                      <div className="">
                        <div>
                          <span>
                            <a
                              target="_blank"
                              className=" UFICommentActorName"
                              dir="ltr"
                              href="https://www.facebook.com/people/Nguy%E1%BB%85n-Tr%C3%A2m/pfbid0F5UaJbgyQaAAWsiWhCe8V4qbeAtFNXLE5PLMQMQCjQQA1tNZVNy3muAjMQEnxmWRl/"
                            >
                              Nguyễn Trâm
                            </a>
                          </span>
                          <div className="_3-8m">
                            <div className="_30o4">
                              <span>
                                <span className="_5mdd">
                                  <span>
                                    Trang này được bác gấu giới thiệu nên hot là
                                    phải
                                  </span>
                                </span>
                              </span>
                              <span />
                            </div>
                          </div>
                          <div className="_2vq9 fsm fwn fcg">
                            <a href="#">Thích</a>
                            <span aria-hidden="true"> · </span>
                            <a href="#">Phản hồi</a>
                            <span aria-hidden="true"> · </span>
                            <span>
                              <a
                                className="uiLinkSubtle"
                                href="https://link.motphim.vip/phim/not-tram-doi-bac-si-12183.html?fb_comment_id=948193810357656"
                                data-ft='{"tn":"N"}'
                                target="_blank"
                              >
                                <abbr
                                  aria-label="18 giờ trước"
                                  minimize="true"
                                  className="UFISutroCommentTimestamp livetimestamp"
                                  data-utime={1707662626}
                                  data-minimize="true"
                                  data-shorten="true"
                                >
                                  5 ngày trước
                                </abbr>
                              </a>
                            </span>
                          </div>
                          <div className="_44ri _2pis">
                            <div className="_3-8y clearfix" direction="left">
                              <div className="_ohe">
                                <a
                                  href="https://www.facebook.com/people/Nguy%E1%BB%85n-Ti%E1%BA%BFn-D%C5%A9ng/pfbid02npYE9x7URm4GipvSvzMZ8zVRTAsNybHgn9Nt2pAaukKRVFyGpQLG6tWt3P5QtBcml/"
                                  src=""
                                  target="_blank"
                                  className="img _8o _8s UFIImageBlockImage"
                                >
                                  <img
                                    className=" _1ci img"
                                    src="https://freefire.momovoucher.com/avatar.php?uid=100084611972973"
                                    alt=""
                                  />
                                </a>
                              </div>
                              <div className="">
                                <div
                                  className="UFIImageBlockContent _42ef clearfix"
                                  direction="right"
                                >
                                  <div className="_ohf">
                                    <div />
                                  </div>
                                  <div className="">
                                    <div>
                                      <span>
                                        <a
                                          target="_blank"
                                          className=" UFICommentActorName"
                                          dir="ltr"
                                          href="https://www.facebook.com/people/Nguy%E1%BB%85n-Ti%E1%BA%BFn-D%C5%A9ng/pfbid02npYE9x7URm4GipvSvzMZ8zVRTAsNybHgn9Nt2pAaukKRVFyGpQLG6tWt3P5QtBcml/"
                                        >
                                          Nguyễn Tiến Dũng
                                        </a>
                                      </span>
                                      <div className="_3-8m">
                                        <div className="_30o4">
                                          <span>
                                            <span className="_5mdd">
                                              <span>
                                                thật hả bạn{" "}
                                                <span
                                                  className="_5mfr"
                                                  title="Biểu tượng cảm xúc pacman"
                                                >
                                                  <span
                                                    className="_6qdm"
                                                    style={{
                                                      height: 16,
                                                      width: 16,
                                                      fontSize: 16,
                                                      backgroundImage:
                                                        'url("https://static.xx.fbcdn.net/images/emoji.php/v9/ef8/1.5/16/PACMAN.png")',
                                                    }}
                                                  >
                                                    :V
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                          <span />
                                        </div>
                                      </div>
                                      <div className="_2vq9 fsm fwn fcg">
                                        <a href="#">Thích</a>
                                        <span aria-hidden="true"> · </span>
                                        <a href="#">Phản hồi</a>
                                        <span aria-hidden="true"> · </span>
                                        <span>
                                          <abbr
                                            aria-label="16 giờ trước"
                                            minimize="true"
                                            className="UFISutroCommentTimestamp livetimestamp"
                                            data-utime={1707671852}
                                            data-minimize="true"
                                            data-shorten="true"
                                          >
                                            5 ngày trước
                                          </abbr>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="_44ri _2pis">
                            <div className="_3-8y clearfix" direction="left">
                              <div className="_ohe">
                                <a
                                  href="https://www.facebook.com/people/H%E1%BB%93-Ng%E1%BB%8Dc-H%C3%A0/pfbid02mJjYP1Jg23KgWtMHSWnYLEQPwbL5MBwZHUtSynetF7iivdtca3oZctoRwr2AZEqVl/"
                                  src=""
                                  target="_blank"
                                  className="img _8o _8s UFIImageBlockImage"
                                >
                                  <img
                                    className=" _1ci img"
                                    src="/images/cmt20.jpg"
                                    alt=""
                                  />
                                </a>
                              </div>
                              <div className="">
                                <div
                                  className="UFIImageBlockContent _42ef clearfix"
                                  direction="right"
                                >
                                  <div className="_ohf">
                                    <div />
                                  </div>
                                  <div className="">
                                    <div>
                                      <span>
                                        <a
                                          target="_blank"
                                          className=" UFICommentActorName"
                                          dir="ltr"
                                          href="https://www.facebook.com/people/H%E1%BB%93-Ng%E1%BB%8Dc-H%C3%A0/pfbid02mJjYP1Jg23KgWtMHSWnYLEQPwbL5MBwZHUtSynetF7iivdtca3oZctoRwr2AZEqVl/"
                                        >
                                          Hồ Ngọc Hà
                                        </a>
                                      </span>
                                      <div className="_3-8m">
                                        <div className="_30o4">
                                          <span>
                                            <span className="_5mdd">
                                              <span>
                                                Mới từ tiktok bác Gấu qua ne{" "}
                                                <span
                                                  className="_5mfr"
                                                  title="Biểu tượng cảm xúc pacman"
                                                >
                                                  <span
                                                    className="_6qdm"
                                                    style={{
                                                      height: 16,
                                                      width: 16,
                                                      fontSize: 16,
                                                      backgroundImage:
                                                        'url("https://static.xx.fbcdn.net/images/emoji.php/v9/ef8/1.5/16/PACMAN.png")',
                                                    }}
                                                  >
                                                    :V
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                          <span />
                                        </div>
                                      </div>
                                      <div className="_2vq9 fsm fwn fcg">
                                        <a href="#">Thích</a>
                                        <span aria-hidden="true"> · </span>
                                        <a href="#">Phản hồi</a>
                                        <span aria-hidden="true"> · </span>
                                        <span>
                                          <abbr
                                            aria-label="16 giờ trước"
                                            minimize="true"
                                            className="UFISutroCommentTimestamp livetimestamp"
                                            data-utime={1707671852}
                                            data-minimize="true"
                                            data-shorten="true"
                                          >
                                            5 ngày trước
                                          </abbr>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="_5lm5 _2pi3 _3-8y">
                <div direction="left" className="clearfix">
                  <div className="_ohe lfloat">
                    <i
                      className="img _8o _8r img sp_Ip2XZyfUSLx_1_5x sx_83f987"
                      alt=""
                      data-visualcompletion="css-img"
                    />
                  </div>
                  <div className="">
                    <div className="_42ef _8u">
                      <a
                        target="_blank"
                        href="https://developers.facebook.com/products/social-plugins/comments/?utm_campaign=social_plugins&utm_medium=offsite_pages&utm_source=comments_plugin"
                      >
                        Plugin bình luận trên Facebook
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* react-mount-point-unstable */}
        </div>
      </div>
    </div>
  );
};

export default CommentFb;
