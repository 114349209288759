import React from "react";
import { useForm } from "react-hook-form";
import Input from "./Form/Input";
import CircleInfoIcon from "./icons/CircleInfoIcon";
import ListBox from "./Form/ListBox";
import { listGiftCode, minExchange } from "../constants/mainConst";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useConfirmModal } from "../hooks/useConfirmModal";
import { numeralMoney } from "../utils/cn";

const schema = yup.object().shape({
  uuid: yup.string().required("ID Game không được để trống"),
  giftcode: yup.string(),
});

const GetGiftCode = () => {
  const confirmModalState = useConfirmModal();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid, isSubmitted, isDirty, isSubmitting },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (!data.giftcode) {
      showDialogError(
        "Nhận thất bại",
        `ID Game: ${data.uuid} Bạn cần chọn gói GiftCode để nhận!`
      );
      return;
    }

    showDialogError(
      "Nhận thất bại",
      `ID Game: ${data.uuid} Bạn cần nạp ít nhất ${numeralMoney(
        minExchange
      )}đ để nhận phần quà này!`
    );
    console.log(data);
  };

  const showDialogError = (title, message) => {
    confirmModalState.show({
      title: title,
      description: (
        <p className="px-5 py-1 bg-red-100 text-red-600 rounded-md">
          {message}
        </p>
      ),
      icon: "error",
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-5 w-full">
      <div className="flex items-center justify-center mt-5">
        <span className="text-sm font-semibold">
          <span className="text-[#ff2400]">GÓI GIFTCODE </span>
          <span className="text-orange-500">FREEFIRE</span>
        </span>
      </div>
      <div className="w-full">
        <Input
          type="text"
          name="uuid"
          display="column"
          placeholder="Nhập ID Game Để nhận Code"
          {...register("uuid")}
          label="Nhập Chính Xác ID Game Để Nhận Code:"
        />
        {errors &&
          isSubmitted &&
          !isValid &&
          isDirty &&
          errors.uuid?.message && (
            <div className="w-full mt-1">
              <span className="text-left flex items-center text-xs text-red-500 bg-red-50 pl-4 py-1 rounded-lg font-medium gap-x-2">
                <CircleInfoIcon
                  className={"h-3.5 w-3.5 fill-current text-red-500"}
                />
                {errors.uuid?.message}
              </span>
            </div>
          )}
      </div>
      <ListBox
        options={listGiftCode}
        className={"w-full"}
        setValue={(value) => {
          setValue("giftcode", value.value.toString().toUpperCase());
        }}
        display="column"
        label={"Chọn Gói GiftCode:"}
      />
      <div className="my-6 ">
        <p className="text-[#007bff] mb-3 text-sm">
          Gói Code Được Cấp Bởi Đại Lộ Danh Vọng FreeFire
        </p>
        <p className="text-[#007bff] mb-3 text-sm">
          Lưu Ý: Mỗi ID Chỉ Có Thể Nhận Tối Đa 1 Code Khi Nạp Đủ{" "}
          {numeralMoney(minExchange)}đ
        </p>
        <p className="text-[#dc3545] mb-3 text-sm">
          *Mỗi Code Khi Nhận Tại Đây Sẽ Chỉ Nhập Được Duy Nhất 1 Lượt
        </p>
      </div>

      <button
        type="submit"
        className="w-full bg-[#5cb85c] text-white border border-[#4cae4c] hover:opacity-90 mt-2 py-1.5 mb-5 uppercase text-xs rounded-md"
      >
        Nhận GiftCode Ngay!
      </button>
    </form>
  );
};

export default GetGiftCode;
