import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { cn } from "../../utils/cn";

const Input = forwardRef(
  (
    {
      label,
      id,
      image,
      onChange,
      className,
      name,
      disable,
      placeholder,
      type = "text",
      errors,
      display = "row",
    },
    ref
  ) => {
    return (
      <div
        className={cn(className, {
          "lg:grid items-center lg:grid-cols-7": display === "row",
          "w-full flex flex-col": display === "column",
        })}
      >
        {display === "row" && (
          <div className="lg:col-span-2 flex items-center justify-start mb-2 lg:mb-0">
            <label htmlFor={id} className="">
              {label}
            </label>
            &nbsp;
            {image && (
              <img src={image} alt={label} className="w-9 h-9 rounded-full" />
            )}
          </div>
        )}

        {display === "column" && (
          <label htmlFor={id} className="font-medium text-xs mb-1">
            {label}
          </label>
        )}

        <div
          className={cn({
            "lg:col-span-5": display === "row",
            "w-full": display === "column",
          })}
        >
          <input
            ref={ref}
            name={name}
            type={type}
            className={cn(
              "w-full border px-2.5 py-2 border-gray-300 outline-none rounded-md focus:border-blue-400 focus:shadow focus:shadow-blue-200 transition-shadow duration-500 text-xs",
              {
                "border-red-500": errors,
              }
            )}
            placeholder={placeholder}
            disabled={disable}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
);

Input.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  disable: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  errors: PropTypes.bool,
  display: PropTypes.oneOf(["row", "column"]),
};

export default Input;
