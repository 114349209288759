import React, { Fragment, memo, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { cn } from "../../utils/cn";
import InfoIcon from "../icons/InfoIcon";
import CheckIcon from "../icons/CheckIcon";
import XIcon from "../icons/XIcon";
import { useConfirmModal } from "../../hooks/useConfirmModal";

const typeOptions = {
  success: "border-green-600",
  error: "border-red-600",
  warning: "border-yellow-600",
  info: "border-[#c9dae1]",
};

const ConfirmModal = () => {
  const confirmModalState = useConfirmModal();

  const IconElement = () => {
    switch (confirmModalState.data.icon) {
      default:
        return <InfoIcon className={"h-12 w-12 text-[#c9dae1]"} />;
      case "success":
        return <CheckIcon className={"h-20 w-20 text-green-600"} />;
      case "error":
        return <XIcon className={"h-16 w-16 text-red-600"} />;
      case "warning":
        return <InfoIcon className={"h-12 w-12 text-yellow-600"} />;
    }
  };

  useEffect(() => {
    const handleKey = (event) => {
      if (event.key === "Escape" && confirmModalState.isOpen) {
        event.stopPropagation();
        void confirmModalState.resolve(false);
      }
    };

    window.addEventListener("keydown", handleKey, true);
    return () => {
      window.removeEventListener("keydown", handleKey, true);
    };
  }, [confirmModalState.isOpen]);

  return (
    <Transition.Root show={confirmModalState.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={"relative z-[99]"}
        onClose={() => {
          confirmModalState.resolve(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className=" relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6 ">
                <div>
                  <div
                    className={cn(
                      "mx-auto h-20 w-20 flex items-center justify-center rounded-full border-4",
                      typeOptions[confirmModalState.data.icon]
                    )}
                  >
                    <IconElement />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-[27px] font-semibold leading-normal text-gray-900 capitalize"
                    >
                      {confirmModalState.data.title}
                    </Dialog.Title>
                    <div className="mt-5 text-base text-gray-500 flex justify-center">
                      {confirmModalState.data.description}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 w-full flex justify-end">
                  <button
                    type="button"
                    className="rounded-md bg-main px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main"
                    onClick={() => {
                      confirmModalState.resolve(true);
                    }}
                  >
                    OK
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

ConfirmModal.propTypes = {
  show: PropTypes.bool,
  header: PropTypes.string,
  setShow: PropTypes.func,
  content: PropTypes.node,
  type: PropTypes.oneOf(["success", "error", "warning", "info"]),
};

export default memo(ConfirmModal);
