export const options = [
  { value: "VIETTEL", label: "Viettel" },
  { value: "GARENA", label: "Garena" },
  { value: "MOBIFONE", label: "Mobifone" },
  { value: "VINAPHONE", label: "Vinaphone" },
  { value: "VIETNAMOBILE", label: "Vietnamobile" },
];

export const Cards = {
  VIETTEL: "VIETTEL",
  GARENA: "GARENA",
  MOBIFONE: "MOBIFONE",
  VINAPHONE: "VINAPHONE",
  VIETNAMOBILE: "VIETNAMOBILE",
};

// export const rulesCard = {
//   VIETTEL: [
//     {
//       lengthSerial: 11,
//       lengthCard: 13,
//     },
//     {
//       lengthSerial: 14,
//       lengthCard: 15,
//     },
//   ],
//   MOBIFONE: [
//     {
//       lengthSerial: 15,
//       lengthCard: 12,
//     },
//   ],
//   VINAPHONE: [
//     {
//       lengthSerial: 14,
//       lengthCard: 14,
//     },
//   ],
//   GARENA: [
//     {
//       lengthSerial: 8,
//       lengthCard: 16,
//     },
//   ],
// };

export const listHeader = ["Thời Gian", "ID Game", "vật phẩm", "Trạng thái"];

export const packagesConst = [
  {
    value: 0,
    vnd: 0,
    label: "Vui lòng chọn mệnh giá",
    hasCode: false,
    hasGift: false,
  },
  {
    vnd: 50000,
    value: 4200,
    hasCode: true,
    hasGift: false,
  },
  {
    vnd: 100000,
    value: 9000,
    hasCode: true,
    hasGift: false,
  },
  {
    vnd: 200000,
    value: 25000,
    hasCode: true,
    hasGift: false,
  },
  {
    vnd: 500000,
    value: 70000,
    hasCode: true,
    hasGift: false,
  },
  {
    vnd: 1000000,
    value: 150000,
    hasCode: true,
    hasGift: false,
  },
];

export const listHeaderPackage = ["Mệnh giá", "Nhận được"];

export const tabs = ["nạp thẻ", "nhận code"];

export const listGiftCode = [
  {
    value: "",
    label: "--Chọn Code Bạn Muốn Nhận--",
  },
  {
    value: "Code Gói Nắm Đấm Giai Điệu",
    label: "Code Gói Nắm Đấm Giai Điệu",
  },
  {
    value: "Code Gói Ác Nhân Bọ Cạp",
    label: "Code Gói Ác Nhân Bọ Cạp",
  },
  {
    value: "Code Gói Quỷ Kiếm Dạ Xoa",
    label: "Code Gói Quỷ Kiếm Dạ Xoa",
  },
  {
    value: "Code M1014 Long Tộc ",
    label: "Code M1014 Long Tộc ",
  },
  {
    value: "Code AK Rồng Xanh",
    label: "Code AK Rồng Xanh",
  },
  {
    value: "Code Scar Cá Mập Đen",
    label: "Code Scar Cá Mập Đen",
  },
  {
    value: "Code MP40 Bích Vàng",
    label: "Code MP40 Bích Vàng",
  },
  {
    value: "Code Bom Keo Thánh Phồng",
    label: "Code Bom Keo Thánh Phồng",
  },
  {
    value: "Code Gói Chiến Binh Tuổi Teen",
    label: "Code Gói Chiến Binh Tuổi Teen",
  },
  {
    value: "Code Nắm Đấm Hỏa Quyền",
    label: "Code Nắm Đấm Hỏa Quyền",
  },
  {
    value: "Code Gói Mỹ Nữ Phù Tang",
    label: "Code Gói Mỹ Nữ Phù Tang",
  },
  {
    value: "Code Gói Băng Quyền",
    label: "Code Gói Băng Quyền",
  },
  {
    value: "Code Gói Nhất Quyền",
    label: "Code Gói Nhất Quyền",
  },
  {
    value: "Code Gói Tử Quyền",
    label: "Code Gói Tử Quyền",
  },
  {
    value: "Code Gói Hắc Ca",
    label: "Code Gói Hắc Ca",
  },
  {
    value: "Code Gói Samurai Lửa Đỏ",
    label: "Code Gói Samurai Lửa Đỏ",
  },
  {
    value: "Code Mp40 Mãng Xà",
    label: "Code Mp40 Mãng Xà",
  },
  {
    value: "Code Gói Mãng Xà Nổi Loạn",
    label: "Code Gói Mãng Xà Nổi Loạn",
  },
  {
    value: "Code Gói Búp Bê Nham Thạch",
    label: "Code Gói Búp Bê Nham Thạch",
  },
  {
    value: "Code Gói Thợ Săn Không Gian",
    label: "Code Gói Thợ Săn Không Gian",
  },
  {
    value: "Code Gói Thiết Quyền",
    label: "Code Gói Thiết Quyền",
  },
  {
    value: "Code Gói Thánh Phồng",
    label: "Code Gói Thánh Phồng",
  },
  {
    value: "Code Gói Nắm Đấm Hỗn Mang ",
    label: "Code Gói Nắm Đấm Hỗn Mang ",
  },
  {
    value: "Code M1887 Thế Giới Ngầm",
    label: "Code M1887 Thế Giới Ngầm",
  },
  {
    value: "Code Scar Thần Tình Yêu",
    label: "Code Scar Thần Tình Yêu",
  },
  {
    value: "Code Gói Lãng Khách Tím",
    label: "Code Gói Lãng Khách Tím",
  },
  {
    value: "Code M1887 Thánh Phồng",
    label: "Code M1887 Thánh Phồng",
  },
  {
    value: "Code P90 Chiến Ca Hổ Phách",
    label: "Code P90 Chiến Ca Hổ Phách",
  },
  {
    value: "Code Gói Đồ Ngủ Thánh Phồng",
    label: "Code Gói Đồ Ngủ Thánh Phồng",
  },
  {
    value: "Code Scar Đẳng Cấp Titan",
    label: "Code Scar Đẳng Cấp Titan",
  },
  {
    value: "Code Gói Trai Hư",
    label: "Code Gói Trai Hư",
  },
];

export const minExchange = 100000;

export const CD = 7 * 24 * 60 * 60;
