import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { cn } from "../../utils/cn";
import { Listbox, Transition } from "@headlessui/react";
import ChevronUpDown from "../icons/ChevronUpDown";
import CheckIcon from "../icons/CheckIcon";

const ListBox = ({
  options,
  className,
  label,
  errors,
  setValue,
  isNote,
  display = "row",
  defaultValue,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    defaultValue || options[0]
  );

  useEffect(() => {
    setValue(selectedOption);
  }, [selectedOption]);

  return (
    <div
      className={cn(className, {
        "lg:grid items-center lg:grid-cols-7": display === "row",
        "w-full flex flex-col": display === "column",
      })}
    >
      {display === "row" && (
        <div className="lg:col-span-2 flex items-center">
          <span className="">{label}</span>
        </div>
      )}

      {display === "column" && (
        <span className="font-medium text-xs mb-1">{label}</span>
      )}

      <div
        className={cn({
          "lg:col-span-5 mt-1": display === "row",
          "w-full": display === "column",
        })}
      >
        <Listbox value={selectedOption} onChange={setSelectedOption}>
          <div className="relative w-full">
            <Listbox.Button
              className={cn(
                "w-full cursor-default border px-2.5 py-2 text-xs border-gray-300 outline-none rounded-md focus:border-blue-500 focus-within:border-blue-500 flex items-center",
                {
                  "border-red-500": errors,
                }
              )}
            >
              <span className="block">{selectedOption?.label || "Chọn"}</span>
              <span className="pointer-events-none absolute right-0 flex items-center pr-2">
                <ChevronUpDown className={"h-5 w-5 text-gray-400"} />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={
                  "absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg text-sm z-50"
                }
              >
                {options.map((option, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      `relative cursor-default select-none flex items-center py-2 px-2 ${
                        active ? "bg-blue-100 text-cyan-900" : "text-gray-900"
                      }`
                    }
                    value={option}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={cn("block truncate font-normal", {
                            "font-medium": selected,
                          })}
                        >
                          {option?.label}
                        </span>
                        {selected && (
                          <span className="absolute right-2 flex items-center">
                            <CheckIcon
                              className={"w-5 h-5 text-blue-600"}
                            ></CheckIcon>{" "}
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
        {isNote && (
          <p className="text-red-700 text-xs">
            Chú ý: nếu chọn sai mệnh giá sẽ bị mất thẻ
          </p>
        )}
      </div>
    </div>
  );
};

ListBox.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.bool,
  setValue: PropTypes.func,
  isNote: PropTypes.bool,
  display: PropTypes.oneOf(["row", "column"]),
  defaultValue: PropTypes.object,
};

export default ListBox;
