import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const array = [
  {
    label: "Câu hỏi thường gặp",
    href: "#",
  },
  {
    label: "Điều khoản sử dụng",
    href: "#",
  },
  {
    label: "Chính sách bảo mật",
    href: "#",
  },
];

const Bottom = () => {
  return (
    <div className="w-full py-2 pb-5 border-t">
      <div className="lg:w-6/12 w-full lg:mx-auto lg:px-10 px-3.5 flex justify-center items-center">
        {array.map((item, index) => (
          <Fragment key={index}>
            <Link
              to={item.href}
              className="text-blue-500 hover:underline text-sm tracking-tighter"
            >
              {item.label}
            </Link>
            {index !== array.length - 1 && (
              <span className="mx-1.5 text-gray-400 text-sm">|</span>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default Bottom;
